import { Button } from "antd";
import { BodyIndexModal, SelectSurveyModal } from "components";
import images from "constants/images";
import { MEMBER_TRANSLATE } from "pages/PublicMemberDetailPage/PublicMemberDetailPage.translate";
import { useMemberDetailPageContext } from "pages/PublicMemberDetailPage/contexts";
import {
  ActionWrapperStyled,
  AppLogoStyled,
  InfoWrapperStyled,
  InkImgStyled,
  MemberIdStyled,
} from "./MemberInfo.styled";
import { ButtonCheckIn, MeasurementInfo } from "./components";

const TOTAL_CHECK_IN_TEXT = "トレーニング回数:";

function MemberInfo() {
  const {
    totalCheckInTimes,
    bodyIndex,
    memberDetail,
    memberId,
    loading,
    hasError,
    handleUpdateBodyIndex,
    handleCheckInSuccess,
    handleViewSurvey,
  } = useMemberDetailPageContext();

  if (loading || hasError) {
    return (
      <InfoWrapperStyled>
        <InkImgStyled />
        <AppLogoStyled src={images.AppLogoSecondary} alt="logo" />
      </InfoWrapperStyled>
    );
  }

  return (
    <InfoWrapperStyled>
      <InkImgStyled />
      <AppLogoStyled src={images.AppLogoSecondary} alt="logo" />

      <MemberIdStyled>
        会員ID
        <br />
        {memberDetail?.memberAxtosId}
      </MemberIdStyled>

      <ButtonCheckIn
        className="btn-check-in"
        trainingPrograms={memberDetail?.programs}
        memberId={memberId}
        onCheckInSuccess={handleCheckInSuccess}
      />

      <p className="total-check-in-date">
        {TOTAL_CHECK_IN_TEXT}
        {totalCheckInTimes}回
      </p>

      <MeasurementInfo className="measurement-info" data={bodyIndex} />

      <ActionWrapperStyled>
        <SelectSurveyModal
          gymSurveyList={memberDetail?.phaseResponses}
          onSelect={handleViewSurvey}
          autoSelect
        >
          <Button block size="large">
            {MEMBER_TRANSLATE.TITLE_VIEW_SURVEY}
          </Button>
        </SelectSurveyModal>

        <BodyIndexModal
          onSubmit={handleUpdateBodyIndex}
          title={MEMBER_TRANSLATE.TITLE_EDIT_BODY_INDEX}
          initialValues={bodyIndex}
          memberDetail={memberDetail}
        >
          <Button block size="large">
            {MEMBER_TRANSLATE.BTN_EDIT_BODY_INDEX}
          </Button>
        </BodyIndexModal>
      </ActionWrapperStyled>
    </InfoWrapperStyled>
  );
}

export { MemberInfo };
