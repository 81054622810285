import { ProgramMenuType } from "constants/program";
import { TAppInformation } from "layouts";

export type TMember = {
  memberId: string;
  birthday: string;
};

export type TMemberDetail = {
  id: string;
  memberAxtosId: string;
  programs?: TTrainingProgram[];
  history?: THistoryCheckIn[];
  bodyIndexHistories: TBodyIndexInfo[];
  bodyIndex: TBodyIndexInfo;

  // History do survey of member
  phaseResponses: TSurveyPhaseResponse[];

  height: number;
  weight: number;
  bodyFat: number;
  minBloodPressure?: number;
  maxBloodPressure?: number;
  heartbeat?: number;

  numOfTrainingSession: number;
  latestCheckIn?: string;
  createdAt: string;
};

export type TTrainingProgram = {
  id: string;
  latestCheckIn: string;
  numOfTrainingSession: number;
  programMaster: { id: string; programCode: string; programName: string };
  programMenu: ProgramMenuType;
  exerciseMenuPurpose: string | null;
  index: number;
};

export type THistoryCheckIn = {
  checkedInDate: string;
  program?: string;
};

export type TBodyIndexInfo = {
  id?: string;
  weight: number;
  height: number;
  bodyFat: number;
  maxBloodPressure?: number;
  minBloodPressure?: number;
  heartbeat?: number;

  // Others
  bmi?: number | string;
  storeId?: string;
  createdAt?: string;
};

export type TSurveyPhaseResponse = {
  surveyCategory?: number;
  phaseNumber: number;
  updatedAt?: string;
};

export type TMemberListItem = {
  id: string;
  memberAxtosId: string;
  programs?: TTrainingProgram[];

  // History do survey of member
  phaseResponses?: TSurveyPhaseResponse[];

  height: number;
  weight: number;
  bodyFat: number;
  minBloodPressure?: number;
  maxBloodPressure?: number;
  heartbeat?: number;
  bmi?: number;

  numOfTrainingSession: number;
  latestCheckIn?: string;
  birth?: string;
  createdAt: string;
};

export enum EMemberTrainingStatus {
  PENDING = 0,
  TRAINING = 1,
  ALL = 2,
}

export type TTotalStoreResponse = {
  totalStore: number;
};

export type TTotalEmployeesResponse = {
  totalUser: number;
};

export type TFileFAQsResponse = {
  titleFAQs: string;
  faqs: TAppInformation[];
};
