import { TabsProps } from "antd";
import { SURVEY_CATEGORY_TITLES } from "constants/translate";
import {
  TGetSurveyDetailByPhaseRes,
  TGetSurveyDetailRes,
} from "features/survey/surveyApi";
import {
  SURVEY_PART_LIST_CONFIGS,
  getQuestionListByCategoryForEditAndDetail,
} from "helpers/survey";
import { SurveyForm } from "layouts/SurveyLayout/components";
import {
  EGymQuestionCategory,
  EQuestionCategoryPhaseIntermediate,
  ESurveyPhase,
  TQuestionCategory,
  TQuestionFormItem,
} from "model/survey";

type TSurveyByCategory = {
  [key in
    | EGymQuestionCategory
    | EQuestionCategoryPhaseIntermediate]?: TQuestionFormItem[];
};

function splitQuestionByCategory({
  questionList,
  surveyPhase,
}: {
  questionList: TGetSurveyDetailRes[];
  surveyPhase: ESurveyPhase;
}): TSurveyByCategory {
  return (SURVEY_PART_LIST_CONFIGS[surveyPhase] as TQuestionCategory[]).reduce(
    (result: TSurveyByCategory, category) => ({
      ...result,
      [category]: getQuestionListByCategoryForEditAndDetail({
        category,
        questionList,
      }),
    }),
    {}
  );
}

export function getSurveyTabItems(
  surveyData: TGetSurveyDetailByPhaseRes,
  phase: ESurveyPhase
): TabsProps["items"] {
  let formattedSurvey: TSurveyByCategory = {};

  if (phase === ESurveyPhase.GYM_PHASE_1) {
    formattedSurvey = splitQuestionByCategory({
      questionList: surveyData.phaseBeginner || [],
      surveyPhase: phase,
    });
  } else if (phase === ESurveyPhase.GYM_PHASE_2) {
    formattedSurvey = splitQuestionByCategory({
      questionList: surveyData.phaseIntermediate || [],
      surveyPhase: phase,
    });
  } else if (phase === ESurveyPhase.JUNIOR_PHASE) {
    formattedSurvey = splitQuestionByCategory({
      questionList: surveyData.junior || [],
      surveyPhase: phase,
    });
  } else if (phase === ESurveyPhase.ADULT_PHASE) {
    formattedSurvey = splitQuestionByCategory({
      questionList: surveyData.adult || [],
      surveyPhase: phase,
    });
  }

  return Object.keys(formattedSurvey).map((category, index) => {
    return {
      key: index.toString(),
      label: `${index + 1}. ${
        SURVEY_CATEGORY_TITLES[phase][
          category as EGymQuestionCategory & EQuestionCategoryPhaseIntermediate
        ]
      }`,
      children: (
        <SurveyForm
          initialValues={formattedSurvey[category as EGymQuestionCategory]}
          questions={[]}
          readOnly
          formClassName="form-view"
        />
      ),
    };
  });
}
