import { AreaChartOutlined, FileAddOutlined } from "@ant-design/icons";
import { Button, Col, Row, Skeleton } from "antd";
import {
  BMIChart,
  BodyIndexChart,
  ButtonBackPage,
  EmptyContent,
} from "components";
import { calculateBMI } from "helpers";
import { MEMBER_TRANSLATE } from "pages/MemberDetailPage/MemberDetailPage.translate";
import { useMemberDetailPageContext } from "pages/MemberDetailPage/contexts";
import { Link } from "react-router-dom";
import { MemberBodyIndexRoute } from "routers/router";
import {
  ButtonDetailStyled,
  InfoSectionWrapperStyled,
  ProgramActionWrapperStyled,
  SkeletonWrapper,
  StatisticWrapperStyled,
} from "./MemberStatistic.styled";
import {
  EMemberActionStatus,
  MemberAction,
  TrainingCalendar,
  TrainingProgramItem,
} from "./components";
import { DEFAULT_PATHNAME } from "constants/pathname";
import { GLOBAL_TRANSLATE } from "constants/translate";

function MemberStatisticSkeleton() {
  return (
    <StatisticWrapperStyled>
      <SkeletonWrapper gutter={[32, 32]}>
        <Col span={24}>
          <Skeleton.Node active fullSize className="custom-chart-skeleton">
            <AreaChartOutlined />
          </Skeleton.Node>
        </Col>
        <Col span={12}>
          <Skeleton.Node active fullSize className="custom-chart-skeleton">
            <AreaChartOutlined />
          </Skeleton.Node>
        </Col>
        <Col span={12}>
          <Skeleton.Node active fullSize className="custom-chart-skeleton">
            <AreaChartOutlined />
          </Skeleton.Node>
        </Col>
        <Col span={24}>
          <Skeleton active />
        </Col>
        <Col span={24}>
          <Skeleton active />
        </Col>
      </SkeletonWrapper>
    </StatisticWrapperStyled>
  );
}

function MemberStatistic() {
  const {
    historyCheckIn,
    memberId,
    bodyIndex,
    memberDetail,
    loading,
    hasError,
    memberAction,
    handleBackPage,
    bodyIndexHistory,
    navigateToCreateProgramPage,
    navigateToProgramDetailPage,
  } = useMemberDetailPageContext();

  // Partial UIs
  const memberActionUI = memberAction !== undefined && (
    <InfoSectionWrapperStyled span={24}>
      <MemberAction
        type={memberAction}
        memberId={memberId}
        memberAxtosId={memberDetail?.memberAxtosId}
      />
    </InfoSectionWrapperStyled>
  );

  const buttonShowDetails = (
    <Link to={MemberBodyIndexRoute.genPath?.(memberId) || "#"}>
      <ButtonDetailStyled>{MEMBER_TRANSLATE.BTN_DETAIL}</ButtonDetailStyled>
    </Link>
  );

  const BMIUi = (
    <InfoSectionWrapperStyled xs={24} xxl={8}>
      <h2 className="title">{MEMBER_TRANSLATE.BMI}</h2>

      <div className="section-content">
        <BMIChart
          bmi={calculateBMI({
            height: bodyIndex?.height,
            weight: bodyIndex?.weight,
          })}
          extraContent={buttonShowDetails}
        />
      </div>
    </InfoSectionWrapperStyled>
  );

  const MemberWeightChartUi = (
    <InfoSectionWrapperStyled xs={24} sm={12} xxl={8}>
      <h2 className="title">{GLOBAL_TRANSLATE.WEIGHT} (Kg)</h2>

      <div className="section-content">
        <BodyIndexChart
          data={bodyIndexHistory}
          config={{ xField: "date", yField: "weight", yUnit: "kg" }}
          extraContent={buttonShowDetails}
        />
      </div>
    </InfoSectionWrapperStyled>
  );

  const MemberFatChartUi = (
    <InfoSectionWrapperStyled xs={24} sm={12} xxl={8}>
      <h2 className="title">{MEMBER_TRANSLATE.BODY_FAT} (%)</h2>

      <div className="section-content">
        <BodyIndexChart
          data={bodyIndexHistory}
          config={{ xField: "date", yField: "bodyFat", yUnit: "%" }}
          extraContent={buttonShowDetails}
        />
      </div>
    </InfoSectionWrapperStyled>
  );

  const showProgramAction =
    memberAction === EMemberActionStatus.NEED_CREATE_PROGRAM ||
    memberAction === EMemberActionStatus.NEED_CREATE_PROGRAM_PHASE_2;

  const ProgramListUi = (
    <InfoSectionWrapperStyled xs={24} xl={12} xxl={16}>
      <h2 className="title">{MEMBER_TRANSLATE.TRAINING_PROGRAM}</h2>

      <div className="section-content section-list">
        {showProgramAction && (
          <ProgramActionWrapperStyled>
            <FileAddOutlined className="icon" />
            <p className="description">新規トレーニングメニューを作成</p>
            <Button type="primary" onClick={navigateToCreateProgramPage}>
              {/* !Add space end of text => to fix issue `auto add space between 2 chars in button` of antd */}
              作成{""}
            </Button>
          </ProgramActionWrapperStyled>
        )}

        {memberDetail?.programs?.map((program, index) => {
          return (
            <TrainingProgramItem
              key={index}
              data={program}
              onClick={() => navigateToProgramDetailPage(program)}
            />
          );
        })}
      </div>
    </InfoSectionWrapperStyled>
  );

  const trainingCalendarUi = (
    <InfoSectionWrapperStyled xs={24} xl={12} xxl={8}>
      <h2 className="title">{MEMBER_TRANSLATE.TRAINING_HISTORY}</h2>

      <div className="section-content">
        <TrainingCalendar
          history={historyCheckIn}
          startDate={memberDetail?.createdAt}
          lastCheckInDate={memberDetail?.latestCheckIn}
        />
      </div>
    </InfoSectionWrapperStyled>
  );

  if (loading) {
    return <MemberStatisticSkeleton />;
  }

  if (hasError) {
    return (
      <StatisticWrapperStyled>
        <ButtonBackPage
          fallbackPath={DEFAULT_PATHNAME}
          className="btn-back"
          onBackPage={handleBackPage}
        />
        <EmptyContent className="no-content" />
      </StatisticWrapperStyled>
    );
  }

  return (
    <StatisticWrapperStyled>
      {/* TODO: Change fallback path to search member page */}
      <ButtonBackPage
        fallbackPath={DEFAULT_PATHNAME}
        className="btn-back"
        onBackPage={handleBackPage}
      />

      <Row gutter={[24, 24]} className="main-content">
        {memberActionUI}

        {BMIUi}

        {MemberWeightChartUi}

        {MemberFatChartUi}

        {ProgramListUi}

        {trainingCalendarUi}
      </Row>
    </StatisticWrapperStyled>
  );
}

export { MemberStatistic };
