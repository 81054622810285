import { DEFAULT_GUID } from "constants/common";
import {
  EGymQuestionCategory,
  EQuestionType,
  ESurveyCategory,
  TQuestion,
} from "model/survey";

export const SURVEY_CREATION_FORM = {
  SURVEY_PART: {
    NAME: "surveyPart",
  },
  SURVEY_PART_TITLE: {
    NAME: "title",
  },
  SURVEY_PART_CATEGORY: {
    NAME: "category",
  },
  SURVEY_PART_QUESTIONS: {
    NAME: "questions",
  },
  QUESTION_NAME: {
    NAME: "name",
  },
  QUESTION_TYPE: {
    NAME: "type",
  },
  OPTIONS: {
    NAME: "options",
  },
  IS_REQUIRED: {
    NAME: "isRequired",
  },
  IS_LOCKED: {
    NAME: "isLocked",
  },
  HAS_OTHER: {
    NAME: "hasOther",
  },
  QUESTION_ID: {
    NAME: "id",
  },
  SURVEY_CATEGORY: {
    NAME: "surveyCategory",
  },
};

export const SURVEY_FORM_NAME = "survey-creation-form";

export const initialQuestion: TQuestion = {
  category: EGymQuestionCategory.OTHER,
  id: "",
  name: "",
  order: 0,
  type: EQuestionType.SINGLE_RADIO,
  description: "",
  hasOther: false,
  isRequired: true,
  options: ["選択肢1"],
  otherAnswer: "",
  dependAnswer: "",
  dependQuestionId: DEFAULT_GUID,
  surveyCategory: ESurveyCategory.GYM,
};
