import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "configs/axiosInstance";
import {
  TGeneratedProgramParams,
  TMemberShumokuHistoryResponse,
  TMemberShumokuResponse,
  TProgramResponse,
  TProgramSurveyResponse,
  TSaveMemberShumokusCreationParams,
  TSaveProgramResponse,
  TSavedProgramParams,
  TShumoku,
  TUpdateMemberShumokuParams,
  TUpdatedProgramParams,
} from "model/program";
import { TResponse, TResponseWithPagination } from "types/service";

const programApi = {
  generateProgram: async (param: TGeneratedProgramParams) => {
    const URL = "/generate-program";
    const response: TResponseWithPagination<TProgramResponse> =
      await axiosInstance.post(URL, param);
    return response;
  },

  saveProgram: async (param: TSavedProgramParams) => {
    const URL = "/save-program";
    const response: TResponse<TSaveProgramResponse> = await axiosInstance.post(
      URL,
      param
    );
    return response;
  },

  saveMemberShumokusCreation: async (
    param: TSaveMemberShumokusCreationParams
  ) => {
    const URL = "/member-shumoku/upsert-many";
    const response: TResponse = await axiosInstance.post(URL, param);
    return response;
  },

  getProgramDetail: async (id: string, memberId: string) => {
    const URL = `/get-program-member-by-id?id=${id}&memberId=${memberId}`;
    const response: TResponseWithPagination<TProgramResponse> =
      await axiosInstance.get(URL);
    return response;
  },

  updateProgram: async (id: string, param: TUpdatedProgramParams) => {
    const URL = `/update-program-member-by-id?id=${id}`;
    const response: TResponseWithPagination<TProgramResponse> =
      await axiosInstance.put(URL, param);
    return response;
  },

  updateMemberShumoku: async (param: TUpdateMemberShumokuParams) => {
    const URL = "/member-shumoku/upsert";
    const response: TResponseWithPagination<TMemberShumokuResponse> =
      await axiosInstance.post(URL, param);
    return response;
  },

  getMemberShumokuHistory: async (memberShumokuId: string) => {
    const URL = `member-shumoku/history/${memberShumokuId}`;

    const response: TResponse<TMemberShumokuHistoryResponse[]> =
      await axiosInstance.get(URL);
    return response;
  },

  getShumokus: async (name: string, storeId: string) => {
    const URL = "/get-shumokus";
    const config: AxiosRequestConfig = {
      params: {
        name,
        storeId,
      },
    };
    const response: TResponseWithPagination<TShumoku[]> =
      await axiosInstance.get(URL, config);
    return response;
  },

  getShumokuByCodeAndName: async (
    name: string,
    code: string,
    storeId: string
  ) => {
    const URL = "/get-shumoku-by-code-and-name";
    const config: AxiosRequestConfig = {
      params: {
        name,
        code,
        storeId,
      },
    };
    const response: TResponse<TShumoku> = await axiosInstance.get(URL, config);
    return response.data;
  },

  getPublicProgram: async (userId: string, memberProgramId: string) => {
    const URL = "/get-program-member-by-id-public";
    const config: AxiosRequestConfig = {
      params: {
        id: memberProgramId,
        userId,
      },
    };
    const response: TResponseWithPagination<TProgramResponse> =
      await axiosInstance.get(URL, config);
    return response;
  },

  getProgramSurvey: async (memberAxtosId: string, phaseNumber?: number) => {
    const URL = "/auto-fill-generate-program";
    const config: AxiosRequestConfig = {
      params: {
        memberAxtosId,
        phaseNumber,
      },
    };
    const response: TResponseWithPagination<TProgramSurveyResponse> =
      await axiosInstance.get(URL, config);
    return response;
  },

  updateProgramForMember: async (data: TUpdateProgramForMemberReq) => {
    const URL = "update-program-for-member";
    const response: TResponseWithPagination<TProgramResponse> =
      await axiosInstance.put(URL, data);
    return response;
  },
};

export type TGetPublicProgramReq = {
  userId: string;
  memberProgramId: string;
};

export type TGetSurveyReq = {
  memberAxtosId: string;
  phaseNumber?: number;
};

export type TUpdateProgramForMemberReq = {
  programMemberId: string;
  programBaseRequest: {
    memberId: string;
    programMenu: number;
    frequency: number;
    duration: number;
    phaseNumber: number;
    exerciseMenuPurpose: string | null;
  };
};
export default programApi;
