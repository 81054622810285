import { FormOutlined, RightOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import {
  ADULT_SCHOOL_SURVEY,
  CUSTOM_MODAL_CLASS_NAME,
  DATE_FORMAT,
  DEFAULT_SURVEY_PHASE_TEXT,
  EMPTY_STRING,
  GYM_SURVEY,
  JUNIOR_SCHOOL_SURVEY,
} from "constants/common";
import { dayjsLocalFromUTC } from "helpers";
import { TSurveyPhaseResponse } from "model/member";
import { Fragment, ReactElement, cloneElement, useState } from "react";
import {
  SurveyItemWrapperStyled,
  SurveyTypeWrapperStyled,
} from "./SelectSurveyModal.styled";
import { ESurveyPhase } from "model/survey";

type TSurveyListForModal = Omit<TSurveyPhaseResponse, "updatedAt"> & {
  phaseName?: string;
  updatedAt?: string;
};

type TSelectSurveyModalProps = {
  children: ReactElement;
  gymSurveyList?: TSurveyListForModal[];
  juniorSurvey?: TSurveyListForModal;
  adultSurvey?: TSurveyListForModal;
  onSelect: (phaseNumber: ESurveyPhase, updatedAt?: string) => void;
  autoSelect?: boolean; // Allow auto select survey that is selected before
  modalTitle?: string;
};

function getSurveyTitle({
  phaseName,
  phaseNumber,
}: {
  phaseNumber: ESurveyPhase;
  phaseName?: string;
}) {
  if (phaseName) return phaseName;

  return `アンケート結果（${phaseNumber}回目）`;
}

function SelectSurveyDetailModal(props: TSelectSurveyModalProps) {
  const {
    children,
    gymSurveyList = [],
    juniorSurvey,
    adultSurvey,
    onSelect,
    modalTitle = "アンケート結果を選択してください。",
  } = props;

  const [openModalSelect, setOpenModalSelect] = useState(false);

  const handleOpenModalSelection = () => {
    setOpenModalSelect(true);
  };

  const handleSelectSurvey = (phaseNumber: number, updatedAt?: string) => {
    onSelect(phaseNumber, updatedAt);
    setOpenModalSelect(false);
    localStorage.setItem(DEFAULT_SURVEY_PHASE_TEXT, phaseNumber.toString());
  };

  return (
    <Fragment>
      {cloneElement(children, {
        onClick: () => {
          // !To make sure children are clickable => children have to have a prop named "onClick"
          children.props.onClick?.();
          handleOpenModalSelection();
        },
      })}

      <Modal
        open={openModalSelect}
        onCancel={() => {
          setOpenModalSelect(false);
        }}
        centered
        width={572}
        maskClosable={false}
        title={modalTitle}
        className={CUSTOM_MODAL_CLASS_NAME}
        footer={null}
      >
        <Fragment>
          {gymSurveyList.length > 0 && (
            <SurveyTypeWrapperStyled>
              <div className="icon-survey-wrapper">
                <FormOutlined />
              </div>
              <div className="survey-info">
                <p className="survey-name">{GYM_SURVEY}</p>
              </div>
            </SurveyTypeWrapperStyled>
          )}
          <Fragment>
            {gymSurveyList?.map((phase, index) => {
              return (
                <SurveyItemWrapperStyled
                  key={`${phase.phaseNumber}-${index}`}
                  onClick={() =>
                    handleSelectSurvey(phase.phaseNumber, phase.updatedAt)
                  }
                >
                  <div className="survey-info">
                    <p className="survey-name">
                      {getSurveyTitle({
                        phaseNumber: phase.phaseNumber,
                        phaseName: phase.phaseName,
                      })}
                    </p>

                    {phase.updatedAt && (
                      <p className="survey-date">
                        更新日:{" "}
                        <strong>
                          {phase.updatedAt
                            ? dayjsLocalFromUTC(phase.updatedAt).format(
                                DATE_FORMAT
                              )
                            : EMPTY_STRING}
                        </strong>
                      </p>
                    )}
                  </div>
                  <div className="icon-right-wrapper">
                    <RightOutlined />
                  </div>
                </SurveyItemWrapperStyled>
              );
            })}
          </Fragment>
          {adultSurvey && (
            <SurveyTypeWrapperStyled
              onClick={() =>
                handleSelectSurvey(
                  ESurveyPhase.ADULT_PHASE,
                  adultSurvey?.updatedAt
                )
              }
            >
              <div className="icon-survey-wrapper">
                <FormOutlined />
              </div>
              <div className="survey-info">
                <p className="survey-name">{ADULT_SCHOOL_SURVEY}</p>
                {adultSurvey?.updatedAt && (
                  <p className="survey-date">
                    更新日:{" "}
                    <strong>
                      {adultSurvey.updatedAt
                        ? dayjsLocalFromUTC(adultSurvey.updatedAt).format(
                            DATE_FORMAT
                          )
                        : EMPTY_STRING}
                    </strong>
                  </p>
                )}
              </div>
              <div className="icon-right-wrapper">
                <RightOutlined />
              </div>
            </SurveyTypeWrapperStyled>
          )}

          {juniorSurvey && (
            <SurveyTypeWrapperStyled
              onClick={() =>
                handleSelectSurvey(
                  ESurveyPhase.JUNIOR_PHASE,
                  juniorSurvey?.updatedAt
                )
              }
            >
              <div className="icon-survey-wrapper">
                <FormOutlined />
              </div>
              <div className="survey-info">
                <p className="survey-name">{JUNIOR_SCHOOL_SURVEY}</p>
                {juniorSurvey?.updatedAt && (
                  <p className="survey-date">
                    更新日:{" "}
                    <strong>
                      {juniorSurvey.updatedAt
                        ? dayjsLocalFromUTC(juniorSurvey.updatedAt).format(
                            DATE_FORMAT
                          )
                        : EMPTY_STRING}
                    </strong>
                  </p>
                )}
              </div>
              <div className="icon-right-wrapper">
                <RightOutlined />
              </div>
            </SurveyTypeWrapperStyled>
          )}
        </Fragment>
      </Modal>
    </Fragment>
  );
}

export { SelectSurveyDetailModal };
