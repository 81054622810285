import {
  EAdultQuestionCategory,
  EGymQuestionCategory,
  EJuniorQuestionCategory,
  EQuestionCategoryPhaseIntermediate,
  ESurveyPhase,
} from "model/survey";

export const GLOBAL_TRANSLATE = {
  CLOSE: "終了",
  DURATION: "時間",
  NUMBER_OF_REPS: "回数",
  WEIGHT: "体重",
  HEIGHT: "身長",
  BMI: "BMI",
  BODY_FAT: "体脂肪",
  CONFIRM_FINISH_SURVEY: "アンケートを終了してもよろしいでしょうか。",
};

export const SURVEY_CATEGORY_TITLES: {
  [phase in ESurveyPhase]: {
    [category in EGymQuestionCategory &
      EQuestionCategoryPhaseIntermediate &
      EJuniorQuestionCategory &
      EAdultQuestionCategory]: string;
  };
} = {
  [ESurveyPhase.GYM_PHASE_1]: {
    [EGymQuestionCategory.PERSONAL_INFO]: "パーソナルデータ",
    [EGymQuestionCategory.TRAINING_REQUEST]: "トレーニングへの要望",
    [EGymQuestionCategory.DIET]: "健康履歴",
    [EGymQuestionCategory.OTHER]: "その他",
  },
  [ESurveyPhase.GYM_PHASE_2]: {
    [EQuestionCategoryPhaseIntermediate.TRAINING_REQUEST]: "トレーニングの要望",
    [EQuestionCategoryPhaseIntermediate.INJURIES_HISTORY]: "既往歴",
    [EQuestionCategoryPhaseIntermediate.LAST_TRAINING_PROGRAM]:
      "成果の振り返り",
  },
  [ESurveyPhase.JUNIOR_PHASE]: {
    [EJuniorQuestionCategory.PERSONAL_INFO]: "パーソナルデータ",
    [EJuniorQuestionCategory.REQUESTS_TO_THE_SCHOOL]: "スクールへの要望",
    [EAdultQuestionCategory.OTHER]: "その他",
  },
  [ESurveyPhase.ADULT_PHASE]: {
    [EAdultQuestionCategory.PERSONAL_INFO]: "パーソナルデータ",
    [EAdultQuestionCategory.REQUESTS_TO_THE_SCHOOL]: "スクールへの要望",
    [EAdultQuestionCategory.ABOUT_YOUR_EXPERIENCE]: "ご経験について",
    [EAdultQuestionCategory.OTHER]: "その他",
  },
};
