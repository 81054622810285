import styled from "styled-components";

export const UnstyledList = styled.ul`
  list-style-type: none;
  padding-left: 16px;
`;

export const ListItemStyled = styled.li`
  list-style-type: auto;
  padding: 5px;
`;

export const ModalContentStyled = styled.div`
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;

  padding: 24px;
  padding-bottom: 5px;
  margin-left: -24px;
  margin-right: -24px;
  .ant-table-pagination.ant-pagination {
    margin: 16px 16px 0px 16px;
  }

  .ant-form {
    display: flex;
    flex-direction: column;
  }

  .error-field {
    border-color: red;
  }
`;

export const SurveyStatisticDonutChartWrapperStyled = styled.div`
  height: 100%;
  padding: 16px 16px 31.5px;

  display: flex;
  flex-direction: column;

  border: var(--border-config);
  background-color: var(--color-white);

  .question-container {
    display: flex;
    flex-direction: row;
    .chart-title {
      flex: 3;
      margin-bottom: 16px;
      color: #000000d9;
      font-size: var(--font-size-md);
      font-weight: 500;
      line-height: 24px;
    }

    .edit-history {
      display: flex;
      flex: 1 1 0%;
      justify-content: end;
      color: #0087ff;
      font-size: var(--font-size-md);
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
    }
  }

  .chart-container {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 32px;
  }

  .empty-chart-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LegendStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

type TLegendItemStyled = { color?: string };

const getColor = (props: TLegendItemStyled) => {
  return props.color || "var(--color-primary)";
};

export const LegendItemStyled = styled.div<TLegendItemStyled>`
  .legend-item-title {
    position: relative;
    font-size: 12px;

    ::before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      background-color: ${getColor};
      border-radius: 50%;

      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(calc(-100% - 4px), -50%);
    }
  }

  .legend-item-vale {
    margin-right: 8px;
    padding-right: 8px;
    position: relative;

    font-weight: 700;
    line-height: 24px;
    color: ${getColor};

    ::after {
      content: "";
      position: absolute;
      right: 0;

      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 15px;
      background-color: #e4e4e4;
    }
  }

  .legend-item-percent {
    font-size: 12px;
    line-height: 20px;
  }
`;
