import { DEFAULT_MODAL_PROPS } from "constants/common";
import MESSAGES from "constants/messages";
import { GLOBAL_TRANSLATE } from "constants/translate";
import { useAppLoading, useAppModal } from "contexts";
import surveyApi from "features/survey/surveyApi";
import { showErrorNotification } from "helpers/notification";
import {
  mappingSurveyForSubmit,
  splitQuestionsByCategory,
} from "helpers/survey";
import { SurveyLayout, TQuestionByCategory } from "layouts";
import { TSurveyValues } from "model/survey";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  MemberDetailsRoute,
  MemberNewbieRoute,
  TMemberAxtosParams,
} from "routers/router";

const SurveyLayoutPhaseBeginner = SurveyLayout<TSurveyValues>;

function SurveyPage() {
  const [categoryList, setCategoryList] = useState<TQuestionByCategory[]>([]);
  const [surveyValues, setSurveyValues] = useState<TSurveyValues>();

  const { memberAxtosId = "" } = useParams<TMemberAxtosParams>();
  const { phaseNumber = 1 } = useParams();

  const { startLoading, stopLoading } = useAppLoading();
  const { appModal } = useAppModal();
  const navigate = useNavigate();

  // Handlers
  // Submit form
  const saveSurvey = async (values: TSurveyValues) => {
    try {
      startLoading();
      const formattedSurvey = mappingSurveyForSubmit(values);

      const { succeeded, data } = await surveyApi.submitSurveyAndCreateMember({
        questions: formattedSurvey,
        memberAxtosId,
      });

      if (succeeded && data) {
        navigate(MemberDetailsRoute.genPath(data.member.id), {
          replace: true,
        });
      } else {
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      }
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  const handleFinishSurvey = (values: TSurveyValues) => {
    appModal.confirm({
      ...DEFAULT_MODAL_PROPS.CONFIRM,
      title: GLOBAL_TRANSLATE.CONFIRM_FINISH_SURVEY,
      onOk: () => saveSurvey(values),
    });
  };

  const getQuestionList = async () => {
    try {
      startLoading();
      const { data: questionList } = await surveyApi.getSurveyQuestions(
        +phaseNumber
      );
      const _categoryList = splitQuestionsByCategory({
        questionList,
        phaseNumber: +phaseNumber,
      });
      setCategoryList(_categoryList);
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  const handleBackPage = () => {
    navigate(MemberNewbieRoute.genPath(memberAxtosId), { replace: true });
  };

  const handleSaveSurvey = (values: TSurveyValues) => {
    setSurveyValues(values);
  };

  // Effects
  useEffect(() => {
    getQuestionList();
  }, []);

  return (
    <SurveyLayoutPhaseBeginner
      categoryList={categoryList}
      onBackPage={handleBackPage}
      onSaveSurvey={handleSaveSurvey}
      surveyValues={surveyValues}
      onFinishSurvey={handleFinishSurvey}
    />
  );
}

export { SurveyPage };
