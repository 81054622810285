import {
  AppstoreFilled,
  ClockCircleFilled,
  MessageOutlined,
  ProjectOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import {
  SelectShumokuModal,
  TSubmitShumokuModalValue,
} from "components/SelectShumokuModal/SelectShumokuModal";
import {
  DEFAULT_WEIGHT,
  NUMBER_OF_DECIMAL_PLACES,
  TYPE_SHUMOKU_TIMES,
  UNIT_OF_WEIGHT,
} from "constants/common";
import images from "constants/images";
import { BodyIcon, DumbbellIcon, PlaySquare } from "constants/svgs";
import { programActions } from "features/program/programSlice";
import { replaceErrorImage } from "helpers";
import { useAppDispatch } from "hooks";
import { TShumoku, TSubmitMemberShumokuModalValue } from "model/program";
import { FC, Fragment, ReactNode, useState } from "react";
import { ShumokuItemStyled } from "./ShumokuItemCreation.styled";
import { ShumokuVideoCreation } from "./components";
import { UpdateMemberShumokuModal } from "components/UpdateMemberShumokuModal/UpdateMemberShumokuModal";

type TProps = {
  data: TShumoku;
  orderNumber?: string;
  type?: string;
  isUpdate?: boolean;
  isExtensionComponent?: boolean;
  freeId?: number;
};

const TEXT_TRANSLATION = {
  UPDATE: "編集",
};

function ShumokuInfoItem({
  icon,
  value,
}: {
  type?: string;
  icon: ReactNode;
  value?: string | number | null;
  isDisplayHistory?: boolean | null;
  memberShumokuId?: string | undefined;
}) {
  if (!value) return <Fragment />;

  return (
    <div className="shumoku-info-item">
      <span className="icon">{icon}</span>
      <span className="content">{value}</span>
    </div>
  );
}

const ShumokuItemCreation: FC<TProps> = (props) => {
  const { data, orderNumber, isUpdate, isExtensionComponent, freeId } = props;

  const [openVideo, setOpenVideo] = useState(false);
  const [weightLevelCreation, setWeightLevelCreation] = useState<number>();
  const [noteCreation, setNoteCreation] = useState<string>();

  const dispatch = useAppDispatch();

  const handleVideoClick = () => {
    setOpenVideo(true);
  };

  const handleUpdateShumoku = (value: TSubmitShumokuModalValue) => {
    const data = {
      ...value,
      freeId,
    };

    dispatch(
      programActions.setExtensions({
        data: data as any as TShumoku & { freeId: number },
      })
    );
  };

  const handleUpdateMemberShumoku = (value: TSubmitMemberShumokuModalValue) => {
    const data = { ...value };
    setWeightLevelCreation(value.weightLevel);
    setNoteCreation(value.note);
    dispatch(programActions.setMemberShumokus({ data: data }));
  };

  const isTimeShumokuType =
    data?.shumokuTimes.slice(-1) === TYPE_SHUMOKU_TIMES.TIMES;

  const shumokuInfoList = [
    {
      key: "location",
      icon: <BodyIcon />,
      value: data?.location,
    },
    {
      key: "shumokuTimes",
      icon: isTimeShumokuType ? <RetweetOutlined /> : <ClockCircleFilled />,
      value: data?.shumokuTimes,
    },
    {
      key: "shumokuSetNumber",
      icon: <AppstoreFilled />,
      value: data?.shumokuSetNumber,
    },
    {
      key: "tempo",
      icon: <ProjectOutlined />,
      value: data?.tempo,
    },
  ];

  const weightLevelValue = () => {
    if (!data?.isDisplayWeightLevel) return null;

    if (weightLevelCreation) {
      return `${Number(weightLevelCreation).toFixed(
        NUMBER_OF_DECIMAL_PLACES
      )}${UNIT_OF_WEIGHT}`;
    }

    if (isExtensionComponent && data?.memberShumoku?.weightLevel) {
      return `${Number(data?.memberShumoku?.weightLevel).toFixed(
        NUMBER_OF_DECIMAL_PLACES
      )}${UNIT_OF_WEIGHT}`;
    }

    return `${DEFAULT_WEIGHT}`;
  };

  const noteValue = () => {
    if (noteCreation) {
      return noteCreation;
    }

    if (isExtensionComponent) {
      return data?.memberShumoku?.note;
    }

    return null;
  };

  const memberShumokuInfoList = [
    {
      key: "weightLevel",
      icon: <DumbbellIcon />,
      value: weightLevelValue(),
    },
    {
      key: "note",
      icon: <MessageOutlined />,
      value: noteValue(),
    },
  ];

  return (
    <Fragment>
      <ShumokuItemStyled>
        <div className="video-index">
          <span className="order-number">{orderNumber}</span>
        </div>

        <div className="program-title">{data?.shumokuName}</div>

        <div className="program-body">
          <div className="video-item" onClick={handleVideoClick}>
            <img
              src={data?.thumbnail || images.ThumbnailImage}
              onError={replaceErrorImage}
              alt={data?.shumokuName}
              loading="lazy"
            />

            <div className="thumbnail-overlay">
              <PlaySquare />
            </div>
          </div>

          <div className="program-index">
            <div className="shumoku-info">
              {shumokuInfoList.map((item) => (
                <ShumokuInfoItem
                  key={item.key}
                  icon={item.icon}
                  value={item.value}
                />
              ))}
            </div>
            <div className="member-shumoku-info">
              {memberShumokuInfoList.map((item) => (
                <ShumokuInfoItem
                  key={item.key}
                  type={item.key}
                  icon={item.icon}
                  value={item.value}
                  isDisplayHistory={
                    data?.memberShumoku?.weightLevel ? true : false
                  }
                  memberShumokuId={data?.memberShumoku?.id}
                />
              ))}
            </div>
          </div>
        </div>

        {isUpdate ? (
          <SelectShumokuModal
            onSubmit={handleUpdateShumoku}
            initialShumoku={data}
          >
            <Button htmlType="button" className="btn-update-shumoku">
              {TEXT_TRANSLATION.UPDATE}
              {""}
            </Button>
          </SelectShumokuModal>
        ) : (
          <UpdateMemberShumokuModal
            onSubmit={handleUpdateMemberShumoku}
            initialShumoku={data}
          >
            <Button htmlType="button" className="btn-update-shumoku">
              {TEXT_TRANSLATION.UPDATE}
              {""}
            </Button>
          </UpdateMemberShumokuModal>
        )}
      </ShumokuItemStyled>

      <ShumokuVideoCreation
        open={openVideo}
        handleOk={() => setOpenVideo(true)}
        handleCancel={() => setOpenVideo(false)}
        video={data}
        weightLevelValue={weightLevelValue()}
        noteValue={noteValue()}
        isExtensionComponent={isExtensionComponent}
      />
    </Fragment>
  );
};

export { ShumokuItemCreation };
