import { FormOutlined, RightOutlined } from "@ant-design/icons";
import { Empty, Modal } from "antd";
import {
  ADULT_SCHOOL_SURVEY,
  CUSTOM_MODAL_CLASS_NAME,
  DATE_FORMAT,
  DEFAULT_SURVEY_PHASE_TEXT,
  EMPTY_STRING,
  GYM_SURVEY,
  JUNIOR_SCHOOL_SURVEY,
} from "constants/common";
import { dayjsLocalFromUTC } from "helpers";
import { TSurveyPhaseResponse } from "model/member";
import { Fragment, ReactElement, cloneElement, useState } from "react";
import {
  SurveyItemWrapperStyled,
  SurveyTypeWrapperStyled,
} from "./SelectSurveyModal.styled";
import { ESurveyPhase } from "model/survey";

type TSurveyListForModal = Omit<TSurveyPhaseResponse, "updatedAt"> & {
  phaseName?: string;
  updatedAt?: string;
};

type TSelectSurveyModalProps = {
  children: ReactElement;
  gymSurveyList?: TSurveyListForModal[];
  onSelect: (phaseNumber: ESurveyPhase) => void;
  autoSelect?: boolean; // Allow auto select survey that is selected before
  hideDate?: boolean;
  modalTitle?: string;
};

function getSurveyTitle({
  phaseName,
  phaseNumber,
}: {
  phaseNumber: ESurveyPhase;
  phaseName?: string;
}) {
  if (phaseName) return phaseName;

  return `アンケート結果（${phaseNumber}回目）`;
}

function SelectSurveyModal(props: TSelectSurveyModalProps) {
  const {
    children,
    gymSurveyList = [],
    onSelect,
    autoSelect,
    hideDate = false,
    modalTitle = "アンケート結果を選択してください。",
  } = props;

  const [openModalSelect, setOpenModalSelect] = useState(false);

  const handleOpenModalSelection = () => {
    if (gymSurveyList.length === 1) {
      onSelect(gymSurveyList[0].phaseNumber);
      localStorage.setItem(
        DEFAULT_SURVEY_PHASE_TEXT,
        gymSurveyList[0].phaseNumber.toString()
      );
      return;
    }

    if (autoSelect) {
      const previousSelectedSurvey = parseInt(
        localStorage.getItem(DEFAULT_SURVEY_PHASE_TEXT) || ""
      );
      const surveyIndex = gymSurveyList.findIndex(
        (survey: TSurveyListForModal) =>
          survey.phaseNumber === previousSelectedSurvey
      );

      if (previousSelectedSurvey && surveyIndex !== -1) {
        onSelect(gymSurveyList[surveyIndex].phaseNumber);
        return;
      }
    }

    setOpenModalSelect(true);
  };

  const handleSelectSurvey = (phaseNumber: number) => {
    onSelect(phaseNumber);
    setOpenModalSelect(false);
    localStorage.setItem(DEFAULT_SURVEY_PHASE_TEXT, phaseNumber.toString());
  };

  return (
    <Fragment>
      {cloneElement(children, {
        onClick: () => {
          // !To make sure children are clickable => children have to have a prop named "onClick"
          children.props.onClick?.();
          handleOpenModalSelection();
        },
      })}

      <Modal
        open={openModalSelect}
        onCancel={() => {
          setOpenModalSelect(false);
        }}
        centered
        width={572}
        maskClosable={false}
        title={modalTitle}
        className={CUSTOM_MODAL_CLASS_NAME}
        footer={null}
      >
        {gymSurveyList.length < 1 && <Empty />}
        <Fragment>
          <SurveyTypeWrapperStyled>
            <div className="icon-survey-wrapper">
              <FormOutlined />
            </div>
            <div className="survey-info">
              <p className="survey-name">{GYM_SURVEY}</p>
            </div>
          </SurveyTypeWrapperStyled>
          <Fragment>
            {gymSurveyList.map((phase, index) => {
              return (
                <SurveyItemWrapperStyled
                  key={`${phase.phaseNumber}-${index}`}
                  onClick={() => handleSelectSurvey(phase.phaseNumber)}
                >
                  <div className="survey-info">
                    <p className="survey-name">
                      {getSurveyTitle({
                        phaseNumber: phase.phaseNumber,
                        phaseName: phase.phaseName,
                      })}
                    </p>

                    {!hideDate && (
                      <p className="survey-date">
                        更新日:{" "}
                        <strong>
                          {phase.updatedAt
                            ? dayjsLocalFromUTC(phase.updatedAt).format(
                                DATE_FORMAT
                              )
                            : EMPTY_STRING}
                        </strong>
                      </p>
                    )}
                  </div>
                  <div className="icon-right-wrapper">
                    <RightOutlined />
                  </div>
                </SurveyItemWrapperStyled>
              );
            })}
          </Fragment>

          <SurveyTypeWrapperStyled
            onClick={() => handleSelectSurvey(ESurveyPhase.ADULT_PHASE)}
          >
            <div className="icon-survey-wrapper">
              <FormOutlined />
            </div>
            <div className="survey-info">
              <p className="survey-name">{ADULT_SCHOOL_SURVEY}</p>
            </div>
            <div className="icon-right-wrapper">
              <RightOutlined />
            </div>
          </SurveyTypeWrapperStyled>
          <SurveyTypeWrapperStyled
            onClick={() => handleSelectSurvey(ESurveyPhase.JUNIOR_PHASE)}
          >
            <div className="icon-survey-wrapper">
              <FormOutlined />
            </div>
            <div className="survey-info">
              <p className="survey-name">{JUNIOR_SCHOOL_SURVEY}</p>
            </div>
            <div className="icon-right-wrapper">
              <RightOutlined />
            </div>
          </SurveyTypeWrapperStyled>
        </Fragment>
      </Modal>
    </Fragment>
  );
}

export { SelectSurveyModal };
