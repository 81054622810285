import { DATE_FORMAT_API } from "constants/common";
import MESSAGES from "constants/messages";
import { useAppLoading } from "contexts";
import dayjs from "dayjs";
import memberApi from "features/member/memberApi";
import statisticApi, {
  TFilterDashboardRequest,
  TFilterDashboardForStoreRequest,
  TStatisticalSurveyByQuestion,
} from "features/statistic/statisticApi";
import { showErrorNotification } from "helpers/notification";
import {
  getPhaseStatusAnalysisForDonutChart,
  getReportByWeekdayForBarChart,
  getTrainingStatusAnalysisForDonutChart,
} from "helpers/statistic";
import { useAppSelector } from "hooks";
import { EMemberTrainingStatus, TMemberListItem } from "model/member";
import {
  TCheckInBarChartValue,
  TMemberStatusDonutChartValue,
  TTCheckInReport,
} from "model/statistic";
import { ESurveyCategory } from "model/survey";
import { EUserRoles } from "model/user";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type THomePageContextValues = {
  storeAdminData: {
    loadingPendingMembers: boolean;
    todayCheckInReport?: TTCheckInReport;
    thisMonthCheckInReport?: TTCheckInReport;
    thisWeekCheckInTrending: TCheckInBarChartValue[];
    allTimeCheckInTrending: TCheckInBarChartValue[];
    pendingMembers: TMemberListItem[];
    memberTrainingStatusAnalysis: TMemberStatusDonutChartValue[];
    memberPhaseStatusAnalysis: TMemberStatusDonutChartValue[];
    statisticalSurveyForStore: TStatisticalSurveyByQuestion[];
  };
  headquarterAdminData: {
    memberTrainingStatusAnalysisForHeadquarter: TMemberStatusDonutChartValue[];
    memberPhaseStatusAnalysisForHeadquarter: TMemberStatusDonutChartValue[];
    memberCheckInTrendingForHeadquarter: TCheckInBarChartValue[];
    statisticalSurveyForHeadQuarter: TStatisticalSurveyByQuestion[];
    selectedSurveyType: ESurveyCategory;
    setSelectedSurveyType: (type: ESurveyCategory) => void;
  };
  setFilter: (filter: TFilterDashboardRequest) => void;
  setFilterForStoreAdmin: (
    filterForStoreAdmin: TFilterDashboardForStoreRequest
  ) => void;
  setChartQuestionIds: (ids: string[]) => void;
};

const initialModalValues: THomePageContextValues = {
  storeAdminData: {
    loadingPendingMembers: true,
    thisWeekCheckInTrending: [],
    allTimeCheckInTrending: [],
    pendingMembers: [],
    memberTrainingStatusAnalysis: [],
    memberPhaseStatusAnalysis: [],
    statisticalSurveyForStore: [],
  },
  headquarterAdminData: {
    memberTrainingStatusAnalysisForHeadquarter: [],
    memberPhaseStatusAnalysisForHeadquarter: [],
    memberCheckInTrendingForHeadquarter: [],
    statisticalSurveyForHeadQuarter: [],
    selectedSurveyType: ESurveyCategory.GYM,
    setSelectedSurveyType: () => {
      //
    },
  },
  setFilter: () => {
    //
  },
  setFilterForStoreAdmin: () => {
    //
  },
  setChartQuestionIds: () => {
    //
  },
};

const HomePageContext =
  createContext<THomePageContextValues>(initialModalValues);

const useHomePageContext = () => useContext(HomePageContext);

function HomePageContextProvider(props: PropsWithChildren) {
  const { children } = props;
  const [filterForStoreAdmin, setFilterForStoreAdmin] =
    useState<TFilterDashboardForStoreRequest>({
      fromDate: "",
      toDate: "",
      gender: "",
      age: [],
    });
  const [filter, setFilter] = useState<TFilterDashboardRequest>({
    fromDate: "",
    toDate: "",
    storeNumber: [],
    gender: "",
    surveyCategory: 0,
    age: [],
  });
  const [, setChartQuestionIds] = useState<string[]>([]);

  // States
  const [todayCheckInReport] = useState<TTCheckInReport>();
  const [thisMonthCheckInReport] = useState<TTCheckInReport>();
  const [thisWeekCheckInTrending] = useState<TCheckInBarChartValue[]>([]);
  const [allTimeCheckInTrending, setAllTimeWeekCheckInTrending] = useState<
    TCheckInBarChartValue[]
  >([]);
  const [memberTrainingStatusAnalysis, setMemberTrainingStatusAnalysis] =
    useState<TMemberStatusDonutChartValue[]>([]);
  const [memberPhaseStatusAnalysis, setMemberPhaseStatusAnalysis] = useState<
    TMemberStatusDonutChartValue[]
  >([]);
  const [pendingMembers, setPendingMembers] = useState<TMemberListItem[]>([]);
  const [loadingPendingMembers, setLoadingPendingMembers] = useState(true);

  // States for headquaters
  const [
    memberTrainingStatusAnalysisForHeadquarter,
    setMemberTrainingStatusAnalysisForHeadquarter,
  ] = useState<TMemberStatusDonutChartValue[]>([]);
  const [
    memberPhaseStatusAnalysisForHeadquarter,
    setMemberPhaseStatusAnalysisForHeadquarter,
  ] = useState<TMemberStatusDonutChartValue[]>([]);

  const [
    memberCheckInTrendingForHeadquarter,
    setMemberCheckInTrendingForHeadquarter,
  ] = useState<TCheckInBarChartValue[]>([]);

  const [statisticalSurveyForStore] = useState<TStatisticalSurveyByQuestion[]>(
    []
  );

  const [statisticalSurveyForHeadQuarter, setStatisticalSurveyForHeadQuarter] =
    useState<TStatisticalSurveyByQuestion[]>([]);

  const [selectedSurveyType, setSelectedSurveyType] = useState(
    ESurveyCategory.GYM
  );

  const { role } = useAppSelector((state) => state.auth.user) || {};
  const isHeadquarterAdmin = role === EUserRoles.HEADQUARTER;
  const hasShowError = useRef(false);
  const { startLoading, stopLoading } = useAppLoading();

  // Sub Handlers
  const handleCallApiError = () => {
    if (hasShowError.current === false) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
      hasShowError.current = true;
    }
  };

  const getCheckInTrendingAllTime = async (
    filterForStoreAdmin: TFilterDashboardForStoreRequest
  ) => {
    try {
      startLoading();
      filterForStoreAdmin = {
        ...filterForStoreAdmin,
        date: filterForStoreAdmin.toDate,
      };
      const { data, succeeded } = await statisticApi.getCheckInTrendingAllTime(
        filterForStoreAdmin
      );
      if (succeeded && data) {
        const _allTimeCheckInTrending = getReportByWeekdayForBarChart({
          report: data,
        });
        setAllTimeWeekCheckInTrending(_allTimeCheckInTrending);
      }
    } catch (error) {
      const _allTimeCheckInTrending = getReportByWeekdayForBarChart({});
      setAllTimeWeekCheckInTrending(_allTimeCheckInTrending);

      handleCallApiError();
    } finally {
      stopLoading();
    }
  };

  const getTrainingStatusAnalysis = async (
    filterForStoreAdmin: TFilterDashboardForStoreRequest
  ) => {
    try {
      startLoading();
      filterForStoreAdmin = {
        ...filterForStoreAdmin,
        date: filterForStoreAdmin.toDate,
      };
      const { data, succeeded } = await statisticApi.getTrainingStatusAnalysis(
        filterForStoreAdmin
      );
      if (succeeded) {
        const dataForDonutChart = getTrainingStatusAnalysisForDonutChart(data);
        setMemberTrainingStatusAnalysis(dataForDonutChart);
      }
    } catch (error) {
      const dataForDonutChart = getTrainingStatusAnalysisForDonutChart();
      setMemberTrainingStatusAnalysis(dataForDonutChart);

      handleCallApiError();
    } finally {
      stopLoading();
    }
  };

  const getPhaseStatusAnalysis = async (
    filterForStoreAdmin: TFilterDashboardForStoreRequest
  ) => {
    try {
      startLoading();
      filterForStoreAdmin = {
        ...filterForStoreAdmin,
        date: filterForStoreAdmin.toDate,
      };
      const { data, succeeded } = await statisticApi.getPhaseStatusAnalysis(
        filterForStoreAdmin
      );
      if (succeeded) {
        const dataForDonutChart = getPhaseStatusAnalysisForDonutChart(data);
        setMemberPhaseStatusAnalysis(dataForDonutChart);
      }
    } catch (error) {
      const dataForDonutChart = getPhaseStatusAnalysisForDonutChart();
      setMemberPhaseStatusAnalysis(dataForDonutChart);

      handleCallApiError();
    } finally {
      stopLoading();
    }
  };

  const getPendingMemberList = async () => {
    try {
      setLoadingPendingMembers(true);
      const { data, succeeded } = await memberApi.getMembers({
        PageNumber: 1,
        PageSize: 5,
        Status: EMemberTrainingStatus.PENDING,
      });
      if (succeeded && data) {
        setPendingMembers(data);
      }
    } catch (error) {
      handleCallApiError();
    } finally {
      setLoadingPendingMembers(false);
    }
  };

  const getTrainingStatusForHeadquarter = async (
    filter: TFilterDashboardRequest
  ) => {
    try {
      // startLoading();
      if (filter.fromDate && filter.toDate) {
        const fromDate = dayjs(filter.fromDate).format(DATE_FORMAT_API);
        const toDate = dayjs(filter.toDate).format(DATE_FORMAT_API);
        filter = {
          ...filter,
          fromDate: fromDate,
          toDate: toDate,
          date: toDate,
        };
      } else {
        filter = { ...filter, date: filter.toDate };
      }
      const { data, succeeded } =
        await statisticApi.getTrainingStatusAnalysisForHeadquarter(filter);
      if (succeeded) {
        const dataForDonutChart = getTrainingStatusAnalysisForDonutChart(data);
        setMemberTrainingStatusAnalysisForHeadquarter(dataForDonutChart);
      }
    } catch (error) {
      const dataForDonutChart = getTrainingStatusAnalysisForDonutChart();
      setMemberTrainingStatusAnalysisForHeadquarter(dataForDonutChart);

      handleCallApiError();
    }
  };

  const getPhaseStatusAnalysisForHeadquarter = async (
    filter: TFilterDashboardRequest
  ) => {
    try {
      // startLoading();
      if (filter.fromDate && filter.toDate) {
        const fromDate = dayjs(filter.fromDate).format(DATE_FORMAT_API);
        const toDate = dayjs(filter.toDate).format(DATE_FORMAT_API);
        filter = {
          ...filter,
          fromDate: fromDate,
          toDate: toDate,
          date: toDate,
        };
      } else {
        filter = { ...filter, date: filter.toDate };
      }
      const { data, succeeded } =
        await statisticApi.getPhaseStatusAnalysisForHeadquarter(filter);
      if (succeeded) {
        const dataForDonutChart = getPhaseStatusAnalysisForDonutChart(data);
        setMemberPhaseStatusAnalysisForHeadquarter(dataForDonutChart);
      }
    } catch (error) {
      const dataForDonutChart = getPhaseStatusAnalysisForDonutChart();
      setMemberPhaseStatusAnalysisForHeadquarter(dataForDonutChart);

      handleCallApiError();
    }
  };

  const getMemberCheckInTrendingForHeadquarter = async (
    filter: TFilterDashboardRequest
  ) => {
    try {
      // startLoading();
      if (filter.fromDate && filter.toDate) {
        const fromDate = dayjs(filter.fromDate).format(DATE_FORMAT_API);
        const toDate = dayjs(filter.toDate).format(DATE_FORMAT_API);
        filter = {
          ...filter,
          fromDate: fromDate,
          toDate: toDate,
          date: toDate,
        };
      } else {
        filter = { ...filter, date: filter.toDate };
      }
      const { data, succeeded } =
        await statisticApi.getCheckInTrendingForHeadquarter(filter);

      if (succeeded) {
        const _thisWeekCheckInTrending = getReportByWeekdayForBarChart({
          report: data,
        });
        setMemberCheckInTrendingForHeadquarter(_thisWeekCheckInTrending);
      }
    } catch (error) {
      const _thisWeekCheckInTrending = getReportByWeekdayForBarChart({
        onlyShowPassDay: true,
      });
      setMemberCheckInTrendingForHeadquarter(_thisWeekCheckInTrending);

      handleCallApiError();
    }
  };

  const getStatisticalSurveyForHeadQuarter = async (
    filterForHeadQuarter: TFilterDashboardRequest
  ) => {
    try {
      // startLoading();
      const responseQuestion = await statisticApi.getListChartQuestions(
        selectedSurveyType
      );
      const questionIds = responseQuestion.data.map((question) => question.id);
      setChartQuestionIds(questionIds);
      const responseSurvey =
        await statisticApi.getStatisticalSurveyByIdForHeadQuarter(
          filterForHeadQuarter,
          questionIds
        );
      setStatisticalSurveyForHeadQuarter(responseSurvey);
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      handleCallApiError();
    }
  };

  const getInitialDataForHeadquarter = async (
    filter: TFilterDashboardRequest
  ) => {
    try {
      startLoading();
      const promises = [
        getPhaseStatusAnalysisForHeadquarter(filter),
        getTrainingStatusForHeadquarter(filter),
        getMemberCheckInTrendingForHeadquarter(filter),
        getStatisticalSurveyForHeadQuarter(filter),
      ];
      // Stop loading when all apis were called
      await Promise.all(promises);
    } catch (error) {
      console.error(error);
      handleCallApiError();
    } finally {
      stopLoading();
    }
  };

  const getInitialDataForAdmin = (
    filterForStoreAdmin: TFilterDashboardForStoreRequest
  ) => {
    // Check in history report
    // getCheckInReportToday();
    // getCheckInReportThisMonth();

    // Check in trending
    // getCheckInTrendingThisWeek();
    getCheckInTrendingAllTime(filterForStoreAdmin);

    // Member analysis
    getTrainingStatusAnalysis(filterForStoreAdmin);
    getPhaseStatusAnalysis(filterForStoreAdmin);

    // Pending member list
    getPendingMemberList();
  };

  // Effects
  useEffect(() => {
    if (isHeadquarterAdmin) {
      getInitialDataForHeadquarter(filter);
    } else {
      getInitialDataForAdmin(filterForStoreAdmin);
    }
  }, [isHeadquarterAdmin, filter, filterForStoreAdmin]);

  return (
    <HomePageContext.Provider
      value={{
        headquarterAdminData: {
          memberPhaseStatusAnalysisForHeadquarter,
          memberTrainingStatusAnalysisForHeadquarter,
          memberCheckInTrendingForHeadquarter,
          statisticalSurveyForHeadQuarter,
          selectedSurveyType,
          setSelectedSurveyType,
        },
        storeAdminData: {
          loadingPendingMembers,
          todayCheckInReport,
          thisMonthCheckInReport,
          thisWeekCheckInTrending,
          allTimeCheckInTrending,
          pendingMembers,
          memberTrainingStatusAnalysis,
          memberPhaseStatusAnalysis,
          statisticalSurveyForStore,
        },
        setFilter,
        setFilterForStoreAdmin,
        setChartQuestionIds,
      }}
    >
      {children}
    </HomePageContext.Provider>
  );
}

export { HomePageContextProvider, useHomePageContext };
