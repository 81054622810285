// Export as url
import HexagonUrl from "assets/svgs/hexagon.svg";
import LoginPattenUrl from "assets/svgs/login-patten.svg";
import MemberConfirmPattenUrl from "assets/svgs/member-confirm-bg.svg";
import PattenInformationUrl from "assets/svgs/patten-information.svg";
import PattenSurveyUrl from "assets/svgs/patten-survey.svg";
import MemberDetailInkUrl from "assets/svgs/patterns/member-detail-ink.svg";
import PolygonSixEdgeUrl from "assets/svgs/polygon-six-edge.svg";
import PolygonWhiteSixEdgeUrl from "assets/svgs/polygon-white.svg";
import ResetPasswordPattenUrl from "assets/svgs/reset-password-pattern.svg";
import PolygonSixEdgeRedUrl from "assets/svgs/polygon-red.svg";
import ThumbNailUrl from "assets/svgs/patterns/thumbnail-pattern.svg";
import ImagePlaceholderUrl from "assets/svgs/pictures/image-placeholder.svg";
import StepIconUrl from "assets/svgs/icons/steps-item-icon.svg";

// Export as component
export { ReactComponent as ArrowLeftIcon } from "assets/svgs/arrow-left.svg";
export { ReactComponent as ArrowRightIcon } from "assets/svgs/arrow-right.svg";
export { ReactComponent as CheckCircle } from "assets/svgs/check-circle.svg";
export { ReactComponent as CloseCircle } from "assets/svgs/close-circle.svg";
export { ReactComponent as EmptyContentSvg } from "assets/svgs/empty-content.svg";
export { ReactComponent as FemaleIcon } from "assets/svgs/female-icon.svg";
export { ReactComponent as GrayLogo } from "assets/svgs/gray-logo.svg";
export { ReactComponent as AppleIcon } from "assets/svgs/icons/apple-icon.svg";
export { ReactComponent as ArmIcon } from "assets/svgs/icons/arm-icon.svg";
export { ReactComponent as ArrowLeftShortIcon } from "assets/svgs/icons/arrow-left-short.svg";
export { ReactComponent as BloodIcon } from "assets/svgs/icons/blood.svg";
export { ReactComponent as BMIIcon } from "assets/svgs/icons/bmi.svg";
export { ReactComponent as ColumnHeightIcon } from "assets/svgs/icons/column-height-icon.svg";
export { ReactComponent as CloseCircleFillWhite } from "assets/svgs/close-circle-fill-white.svg";

// Icons
export { ReactComponent as EyeCloseIcon } from "assets/svgs/icons/eye-close.svg";
export { ReactComponent as EyeOpenIcon } from "assets/svgs/icons/eye-open.svg";
export { ReactComponent as FireIcon } from "assets/svgs/icons/fire-icon.svg";
export { ReactComponent as GoldIcon } from "assets/svgs/icons/gold-icon.svg";
export { ReactComponent as HeightIcon } from "assets/svgs/icons/heigth.svg";
export { ReactComponent as PersonIcon } from "assets/svgs/icons/person-icon.svg";
export { ReactComponent as BodyIcon } from "assets/svgs/icons/body-icon.svg";
export { ReactComponent as WeightIcon } from "assets/svgs/icons/weigth.svg";
export { ReactComponent as LoginImage } from "assets/svgs/login-image.svg";
export { ReactComponent as AppLogo } from "assets/svgs/login-logo.svg";
export { ReactComponent as MaleIcon } from "assets/svgs/male-icon.svg";
export { ReactComponent as NotFoundedMember } from "assets/svgs/not-found-card.svg";
export { ReactComponent as VerifyImage } from "assets/svgs/verify-image.svg";
export { ReactComponent as CalendarIcon } from "assets/svgs/icons/calendar-icon.svg";
export { ReactComponent as DotsSixIcon } from "assets/svgs/icons/dots-six.svg";
export { ReactComponent as HomeIcon } from "assets/svgs/icons/home.svg";
export { ReactComponent as PlaySquare } from "assets/svgs/icons/play-square.svg";
export { ReactComponent as HomePageIcon } from "assets/svgs/icons/homepage-icon.svg";
export { ReactComponent as OtherIcon } from "assets/svgs/icons/other-icon.svg";
export { ReactComponent as CreateIcon } from "assets/svgs/icons/create-icon.svg";
export { ReactComponent as DumbbellIcon } from "assets/svgs/icons/dumbbell-icon.svg";
export { ReactComponent as DumbbellVideoIcon } from "assets/svgs/icons/dumbbell-video-icon.svg";

// Gradient icons
export { ReactComponent as InfoGradientIcon } from "assets/svgs/gradient-icons/info-gradient-icon.svg";
export { ReactComponent as ProfileGradientIcon } from "assets/svgs/gradient-icons/profile-gradient-icon.svg";
export { ReactComponent as UserGradientIcon } from "assets/svgs/gradient-icons/user-gradient-icon.svg";
export { ReactComponent as RightGradientIcon } from "assets/svgs/gradient-icons/right-gradient-icon.svg";
export { ReactComponent as CheckboxGradientIcon } from "assets/svgs/gradient-icons/checkbox-gradient-icon.svg";
export { ReactComponent as RadioGradientIcon } from "assets/svgs/gradient-icons/radio-gradient-icon.svg";
export { ReactComponent as MenuGradientIcon } from "assets/svgs/gradient-icons/menu-gradient-icon.svg";

// Export as URL
export {
  LoginPattenUrl,
  PattenInformationUrl,
  HexagonUrl,
  ResetPasswordPattenUrl,
  PattenSurveyUrl,
  MemberConfirmPattenUrl,
  PolygonSixEdgeUrl,
  PolygonWhiteSixEdgeUrl,
  PolygonSixEdgeRedUrl,
  ThumbNailUrl,
  StepIconUrl,
};

export const patternSvgs = { MemberDetailInkUrl };
export const pictureSvgs = { ImagePlaceholderUrl };
