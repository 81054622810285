export const FILTER_TRANSLATE = {
  STORE: "店舗",
  GENDER: "性別",
  SURVEY_CATEGORY: "調査の種類",
  AGE: "年代",
  ALL_STORE: "全店番",
  FILTER: "検 索",
  ALL: "全",
  TYPE_DATE: "期間指定",
  DATE: "日付を選択",
  PERIOD: "期間設定",
  SELECT_TYPE_DATE: "日付の種類",
  TIME_PICKER: "時間",
  DATE_PICKER: "日",
  WEEK_PICKER: "週",
  MONTH_PICKER: "月",
};
