// =================================== DEFINE PATHNAME ===================================
export const PATHNAME_LIST = {
  // Auth routes
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",

  // Function routes
  HOME: "/",
  SURVEY: "/survey/:phaseNumber/:memberAxtosId",
  SURVEY_PHASE_INTERMEDIATE:
    "/survey/detail/:phaseNumber/:memberId/:memberAxtosId",
  SURVEY_DETAIL: "/survey/view/:memberId",
  SURVEY_EDIT: "/survey/edit/:memberId/:phaseNumber/:step",

  MEMBER_CONFIRMATION: "/member-confirmation",
  MEMBER_DETAIL: "/member-details/:memberId",
  MEMBER_BODY_INDEX: "/member-details/body-index/:memberId",
  PERSONAL_INFO: "/personal-info",
  MEMBER_NEWBIE: "/new-member/:memberAxtosId",
  MEMBER_LIST: "/member-list",
  TRAINING_PROGRAM: "/training-program/:memberId/:phaseNumber",
  TRAINING_PROGRAM_DETAIL: "/training-program-detail",
  SHUMOKU_MANAGEMENT: "/shumoku-management",
  SHUMOKU_MASTER_MANAGEMENT: "/shumoku-master-management",
  SURVEY_MANAGEMENT: "/survey-management/:phaseNumber",
  MENU_TRAINING_EDITING: "/menu-training-editing",
  PROGRAM_DETAIL_EDITING: "/program-detail-editing",

  // Public route
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  FAQ: "/faq",
  PUBLIC_PROGRAM: "/public-program",
  PUBLIC_MEMBER_DETAIL: "/sso/member-detail",
};

export const DEFAULT_PATHNAME = PATHNAME_LIST.HOME;

export const TITLE_LIST = {
  // Auth functions
  LOGIN: "ログイン",
  FORGOT_PASSWORD: "メール確認",
  RESET_PASSWORD: "パスワード忘れた場合",
  MEMBER_CONFIRMATION: "会員登録",

  // Main functions
  HOME: "ホーム",
  SURVEY: "アンケート",
  SURVEY_PHASE_INTERMEDIATE: "アンケート",
  SURVEY_DETAIL: "アンケート",
  SURVEY_EDIT: "アンケート",
  MEMBER_DETAIL: "会員詳細表示",
  MEMBER_BODY_INDEX: "会員詳細表示",
  MEMBER_NEWBIE: "会員詳細表示",
  MEMBER_LIST: "会員詳細",
  TRAINING_PROGRAM: "トレーニングメニューを作成",
  TRAINING_PROGRAM_DETAIL: "トレーニングメニュー詳細",
  PERSONAL_INFO: "設定管理",
  SHUMOKU_MANAGEMENT: "店舗（マスター管理）",
  SURVEY_MANAGEMENT: "アンケート（マスター管理）",
  MENU_TRAINING_EDITING: "目的更新",
  PROGRAM_DETAIL_EDITING: "トレーニングメニュー更新",
  SHUMOKU_MASTER_MANAGEMENT: "種目（マスター管理）",

  // Public pages
  TERMS_AND_CONDITIONS: "利用規約",
  FAQ: "よくある質問",
  PUBLIC_PROGRAM: "トレーニングメニュー",

  // Default
  DEFAULT: "コミットシステム",
};
