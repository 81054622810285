import { createSlice } from "@reduxjs/toolkit";
import {
  TGetProgramParams,
  TProgramResponse,
  TProgramSurveyResponse,
  TSaveMemberShumokusCreationParams,
  TSavedProgramParams,
  TShumoku,
  TSubmitMemberShumokuModalValue,
  TUpdateMemberShumokuParams,
  TUpdatedProgramParams,
} from "model/program";
import { TAction } from "types/common";
import {
  TGetPublicProgramReq,
  TGetSurveyReq,
  TUpdateProgramForMemberReq,
} from "./programApi";

export type TProgramSurvey = {
  programMenu: null | number;
  frequency: number;
  duration: number;
  phaseNumber: number;
  hasOther: string | null;
  questionId: string;
};

export type TQuestionAndAnswerSurvey = {
  questionId: string;
  answer: string;
};

type TInitialState = {
  loading: boolean;
  hasError: boolean;
  hasGetProgramError: boolean;
  programSurvey: TProgramSurvey;
  generatedProgram: TProgramResponse | null;
  extensions: TShumoku[];
  memberShumokus: TSubmitMemberShumokuModalValue[];
  programDetail: TProgramResponse | null;
  isKeepValue: boolean;
  isViewSurvey: boolean;
  isRender: boolean;
  previousMenu: number | null;
  storeId: string;
  publicProgram: TProgramResponse | null;
  questionAndAnswerSurvey: TQuestionAndAnswerSurvey[];
};

const initialState: TInitialState = {
  loading: false,
  hasError: false,
  hasGetProgramError: false,
  programSurvey: {
    programMenu: null,
    frequency: 0,
    duration: 0,
    phaseNumber: 0,
    hasOther: null,
    questionId: "",
  },
  generatedProgram: null,
  extensions: [],
  memberShumokus: [],
  programDetail: null,
  isKeepValue: false,
  isRender: false,
  isViewSurvey: false,
  previousMenu: null,
  storeId: "",
  publicProgram: null,
  questionAndAnswerSurvey: [],
};

const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    generateProgram(state, _action: TAction) {
      state.loading = true;
    },
    generateProgramSuccess(state, action: TAction) {
      state.loading = false;
      state.generatedProgram = action.payload.data;
    },
    generateProgramFailure(state) {
      state.loading = false;
      state.hasError = true;
    },

    setPreviousMenu(state, action) {
      state.previousMenu = action.payload;
    },

    //save program
    saveProgram(state, _action: TAction<TSavedProgramParams>) {
      state.loading = true;
    },
    saveProgramSuccess(state) {
      state.loading = false;
    },
    saveProgramFailure(state) {
      state.loading = false;
      state.hasError = true;
    },

    // save memberShumokus creation
    saveMemberShumokusCreation(
      state,
      _action: TAction<TSaveMemberShumokusCreationParams>
    ) {
      state.loading = true;
    },
    saveMemberShumokusCreationSuccess(state) {
      state.loading = false;
    },
    saveMemberShumokusCreationFailure(state) {
      state.loading = false;
      state.hasError = true;
    },

    // extensions
    setExtensions(state, action: TAction<TShumoku>) {
      const newExtension = action.payload.data;

      const existingIndex = state.extensions.findIndex(
        (extension) =>
          extension.id === newExtension.id ||
          extension.freeId === newExtension.freeId
      );

      if (existingIndex >= 0) {
        state.extensions[existingIndex] = newExtension;
      } else {
        state.extensions = [...state.extensions, newExtension];
      }
      // state.extensions = [...state.extensions, action.payload.data];
    },
    clearAllExtensions(state) {
      state.extensions = [];
    },

    // memberShumokus
    setMemberShumokus(state, action: TAction<TSubmitMemberShumokuModalValue>) {
      const newMemberShumoku = action.payload.data;

      const existingIndex = state.memberShumokus.findIndex(
        (memberShumoku) =>
          memberShumoku.shumokuMasterId === newMemberShumoku.shumokuMasterId
      );

      if (existingIndex >= 0) {
        state.memberShumokus[existingIndex] = newMemberShumoku;
      } else {
        state.memberShumokus = [...state.memberShumokus, newMemberShumoku];
      }
    },
    clearMemberShumokus(state) {
      state.memberShumokus = [];
    },

    //get program
    getProgramDetail(state, _action: TAction<TGetProgramParams>) {
      state.loading = true;
      state.hasGetProgramError = false;
    },
    getProgramDetailSuccess(state, action: TAction<TProgramResponse>) {
      state.loading = false;
      state.programDetail = action.payload.data;
    },
    getProgramDetailFailure(state) {
      state.loading = false;
      state.hasGetProgramError = true;
    },

    //update program
    updateProgram(state, _action: TAction<TUpdatedProgramParams>) {
      state.loading = true;
    },
    updateProgramSuccess(state) {
      state.loading = false;
    },
    updateProgramFailure(state) {
      state.loading = false;
    },
    setIsKeepValue(state, { payload }) {
      state.isKeepValue = payload.data;
    },
    setIsRender(state, { payload }) {
      state.isRender = payload;
    },
    setStoreId(state, { payload }) {
      state.storeId = payload.data;
    },

    //update membershumoku
    updateMemberShumoku(state, _action: TAction<TUpdateMemberShumokuParams>) {
      state.loading = true;
    },
    updateMemberShumokuSuccess(state) {
      state.loading = false;
    },
    updateMemberShumokuFailure(state) {
      state.loading = false;
    },
    setIsKeepValueMemberShumoku(state, { payload }) {
      state.isKeepValue = payload.data;
    },
    setIsRenderMemberShumoku(state, { payload }) {
      state.isRender = payload;
    },
    setStoreIdMemberShumoku(state, { payload }) {
      state.storeId = payload.data;
    },

    // get public program
    getPublicProgram(state, _action: TAction<TGetPublicProgramReq>) {
      state.loading = true;
    },
    getPublicProgramSuccess(state, action: TAction<TProgramResponse>) {
      state.loading = false;
      state.publicProgram = action.payload.data;
    },
    getPublicProgramFailure(state) {
      state.loading = false;
    },

    //get survey
    getProgramSurvey(state, _action: TAction<TGetSurveyReq>) {
      state.loading = true;
    },
    getProgramSurveySuccess(state, action: TAction<TProgramSurveyResponse>) {
      state.loading = false;
      const surveyResponse: TProgramSurvey = {
        duration: action.payload.data.duration,
        frequency: action.payload.data.frequency,
        phaseNumber: action.payload.data.phaseNumber,
        programMenu: action.payload.data.programMenu,

        hasOther: action.payload.data?.hasOther as string,
        questionId: action.payload.data.questionId,
      };

      state.programSurvey = surveyResponse;
      state.questionAndAnswerSurvey = action.payload.data.surveyResponse;
    },
    getProgramSurveyFailure(state) {
      state.loading = false;
    },

    // view survey
    setViewSurvey(state, action: TAction<boolean>) {
      state.isViewSurvey = action.payload.data;
    },
    // set survey
    setProgramSurvey(state, action: TAction<TProgramSurvey>) {
      state.programSurvey = { ...state.programSurvey, ...action.payload.data };
    },

    //update program for member
    updateProgramForMember(
      state,
      _action: TAction<TUpdateProgramForMemberReq>
    ) {
      state.loading = true;
    },
    updateProgramForMemberSuccess(state) {
      state.loading = false;
    },
    updateProgramForMemberFailure(state) {
      state.loading = false;
    },
  },
});

const { actions, reducer } = programSlice;

export { actions as programActions, reducer as programReducer };
