import { FC, useEffect, useRef } from "react";
import {
  ProgramFreeSelectionItemStyled,
  ProgramSectionListStyled,
} from "./ProgramSectionList.styled";
import { TStepResponse } from "model/program";
import { ProgramSectionItemRef } from "../ProgramSectionItem/ProgramSectionItem";
import { useProgramContext } from "pages/TrainingProgramPage/ProgramContext/ProgramContext";
import { useAppDispatch, useAppSelector } from "hooks";
import { programActions } from "features/program/programSlice";
import { useParams } from "react-router-dom";
import { useAppLoading } from "contexts";

const PROGRAM_FREE_DATA = {
  stepNumber: 5,
  stepName: "フリー選択",
  shumokus: [
    {
      id: "",
      shumokuName: "",
      shumokuCode: "",
      shumokuSetNumber: 0,
      shumokuTimes: "",
      hasVideo: false,
      thumbnail: "",
      videoUrl: "",
    },
  ],
};

const ProgramSectionList: FC = () => {
  const programData = useAppSelector((state) => state.program.generatedProgram);
  const loading = useAppSelector((state) => state.program.loading);
  const dispatch = useAppDispatch();
  const { startLoading, stopLoading } = useAppLoading();
  const { sectionRefs } = useProgramContext();
  const { programMenu, frequency, duration, phaseNumber } = useAppSelector(
    (state) => state.program.programSurvey
  );

  const { memberId } = useParams();
  const programSectionListRef = useRef<HTMLDivElement>(null);
  const programFreeSelectionItemRef = useRef<HTMLDivElement | any>(null);

  useEffect(() => {
    dispatch(
      programActions.generateProgram({
        data: {
          memberId: memberId,
          programMenu,
          frequency,
          duration,
          phaseNumber,
        },
      })
    );
  }, [programMenu, frequency, duration, phaseNumber]);

  useEffect(() => {
    if (loading) {
      startLoading();
    } else {
      stopLoading();
    }
    return stopLoading;
  }, [loading]);

  const freeSelectionHeight =
    programFreeSelectionItemRef.current?.getBoundingClientRect().height;

  useEffect(() => {
    const MARGIN_TOP = 272;
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const programFreeSelectionItemHeight =
        programFreeSelectionItemRef.current?.getBoundingClientRect().height;
      const paddingBottom =
        windowHeight - programFreeSelectionItemHeight - MARGIN_TOP;
      programFreeSelectionItemRef.current.style.marginBottom = `${paddingBottom}px`;
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [freeSelectionHeight]);
  return (
    <ProgramSectionListStyled ref={programSectionListRef}>
      {programData?.stepsResponse?.map((section: TStepResponse, index) => {
        return (
          <ProgramSectionItemRef
            ref={sectionRefs?.[index]}
            section={section}
            key={index}
          />
        );
      })}
      <ProgramFreeSelectionItemStyled ref={programFreeSelectionItemRef}>
        <ProgramSectionItemRef
          ref={sectionRefs?.[4]}
          section={PROGRAM_FREE_DATA}
          key={PROGRAM_FREE_DATA.stepNumber}
          type="free"
          isUpdate={true}
        />
      </ProgramFreeSelectionItemStyled>
    </ProgramSectionListStyled>
  );
};

export { ProgramSectionList };
