import {
  FileFilled,
  LogoutOutlined,
  QuestionCircleOutlined,
  ReadFilled,
} from "@ant-design/icons";
import { ButtonBackPage, SelectSurveyModal } from "components";
import { DEFAULT_MODAL_PROPS, EMPTY_STRING } from "constants/common";
import images from "constants/images";
import MESSAGES from "constants/messages";
import { DEFAULT_PATHNAME, PATHNAME_LIST } from "constants/pathname";
import { useAppLoading, useAppModal } from "contexts";
import authApi from "features/auth/authApi";
import { authActions } from "features/auth/authSlice";
import memberApi from "features/member/memberApi";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import { removeAuthToken } from "helpers/auth";
import { showErrorNotification } from "helpers/notification";
import { useAppSelector } from "hooks";
import { ESurveyPhase } from "model/survey";
import { EUserRoles } from "model/user";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SurveyManagementRoute } from "routers/router";
import {
  AXTOS_HEADQUARTER_BUTTONS,
  STORE_ADMIN_BUTTONS,
} from "./PersonalInfoPage.data";
import {
  ContentWrapperStyled,
  PersonalInfoContainerStyled,
  TopHeaderContainerStyled,
} from "./PersonalInfoPage.styled";
import { PERSONAL_INFORMATION_TRANSLATE } from "./PersonalInfoPage.translate";
import { ProfileButtonItem } from "./components/ProfileButtonItem/ProfileButtonItem";

const PersonalInfoPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { startLoading, stopLoading } = useAppLoading();
  const { appModal } = useAppModal();

  const user = useAppSelector((state) => state.auth.user);
  const { role: userRole, store } = user || {};

  const [error, setError] = useState(false);
  const [_totalStore, setTotalStore] = useState(0);

  const dynamicButtonsWithRole = useMemo(() => {
    return userRole === EUserRoles.HEADQUARTER
      ? AXTOS_HEADQUARTER_BUTTONS
      : STORE_ADMIN_BUTTONS;
  }, [userRole]);
  const isHeadQuarter = userRole === EUserRoles.HEADQUARTER;

  const handleLogoutApp = () => {
    async function logout() {
      startLoading();

      try {
        await authApi.logout();
      } catch (e) {
        // do nothing
      } finally {
        navigate(PATHNAME_LIST.LOGIN);
        dispatch(authActions.clearUserInfo());
        removeAuthToken();
        stopLoading();
      }
    }

    appModal.confirm({
      ...DEFAULT_MODAL_PROPS.CONFIRM,
      title: MESSAGES["SCR-W10-MSG-001-TITLE"],
      content: MESSAGES["SCR-W10-MSG-001-CONTENT"],
      onOk: () => {
        logout();
      },
    });
  };

  const navigateToSurveyMaster = (surveyPhase: ESurveyPhase) => {
    navigate(
      SurveyManagementRoute.genPath({
        phaseNumber: surveyPhase,
      })
    );
  };

  useEffect(() => {
    if (error) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    }
  }, [error]);

  useEffect(() => {
    dispatch(shumokuActions.setSelectedStoreIds({ data: [] }));
    dispatch(shumokuActions.setSelectedShumokuMasterIds({ data: [] }));
    dispatch(shumokuActions.setSelectedNumber({ data: 0 }));
  }, []);

  useEffect(() => {
    if (userRole === EUserRoles.HEADQUARTER) {
      getTotalStore();
    }
  }, [userRole]);

  const getTotalStore = async () => {
    try {
      setError(false);
      const { data } = await memberApi.getTotalStore();

      if (data) {
        setTotalStore(data?.totalStore);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
  };

  return (
    <PersonalInfoContainerStyled>
      <TopHeaderContainerStyled>
        <ButtonBackPage
          text="戻る"
          fallbackPath={DEFAULT_PATHNAME}
          className="btn-back"
        />
        <div className="avatarContainer">
          <img
            alt="logo"
            src={
              userRole === EUserRoles.HEADQUARTER
                ? images.AxtoxHeadquarterLogo
                : images.StoreAdminLogo
            }
            className="logo"
          />

          <div className="storeInfoContainer">
            <span className="avatarTitle">
              {userRole === EUserRoles.HEADQUARTER
                ? PERSONAL_INFORMATION_TRANSLATE.AXTOS_HEADQUARTER
                : store?.storeName || EMPTY_STRING}
            </span>

            {userRole === EUserRoles.HEADQUARTER ? (
              <div className="storeInfoContent">
                {/* <div>
                  <span className="titleStore">
                    {PERSONAL_INFORMATION_TRANSLATE.STORE_NUMBER}
                  </span>
                  <span className="valueStore">
                    {numberWithCommas(totalStore)}
                  </span>
                  <span className="titleStore">
                    {PERSONAL_INFORMATION_TRANSLATE.STORE}
                  </span>
                </div> */}
              </div>
            ) : (
              <div className="storeAdminContainer">
                <span className="titleStore">
                  {PERSONAL_INFORMATION_TRANSLATE.STORE_ID}
                </span>
                <span className="valueStore">{` ${
                  store?.storeNumber || EMPTY_STRING
                }`}</span>
              </div>
            )}
          </div>
        </div>
      </TopHeaderContainerStyled>

      <ContentWrapperStyled>
        {/* Actions for role */}
        <div className="role-menu">
          {isHeadQuarter && (
            <SelectSurveyModal
              onSelect={navigateToSurveyMaster}
              gymSurveyList={[
                {
                  phaseNumber: ESurveyPhase.GYM_PHASE_2,
                  phaseName: PERSONAL_INFORMATION_TRANSLATE.SURVEY_NAME_PHASE_2,
                },
                {
                  phaseNumber: ESurveyPhase.GYM_PHASE_1,
                  phaseName: PERSONAL_INFORMATION_TRANSLATE.SURVEY_NAME_PHASE_1,
                },
              ]}
              hideDate
              modalTitle={
                PERSONAL_INFORMATION_TRANSLATE.SELECT_SURVEY_MODAL_TITLE
              }
            >
              <ProfileButtonItem
                title={PERSONAL_INFORMATION_TRANSLATE.SURVEY_MASTER_MANAGEMENT}
                iconLeft={<FileFilled />}
              />
            </SelectSurveyModal>
          )}

          {dynamicButtonsWithRole?.map((item) => {
            return (
              <Link to={item?.pathname} key={item?.key}>
                <ProfileButtonItem
                  title={item?.title}
                  iconLeft={item?.iconLeft}
                />
              </Link>
            );
          })}
        </div>

        {/* Common actions */}
        <div
          className="common-menu"
          style={{
            marginTop: isHeadQuarter ? "40px" : "0",
          }}
        >
          <Link to={PATHNAME_LIST.TERMS_AND_CONDITIONS}>
            <ProfileButtonItem
              title={PERSONAL_INFORMATION_TRANSLATE.TERM_AND_CONDITIONS}
              iconLeft={<ReadFilled />}
            />
          </Link>
          <Link to={PATHNAME_LIST.FAQ}>
            <ProfileButtonItem
              title={PERSONAL_INFORMATION_TRANSLATE.FAQ}
              iconLeft={<QuestionCircleOutlined />}
            />
          </Link>

          <ProfileButtonItem
            title={PERSONAL_INFORMATION_TRANSLATE.LOGOUT}
            className="logoutButton"
            onClick={handleLogoutApp}
            iconLeft={<LogoutOutlined />}
          />
        </div>
      </ContentWrapperStyled>
    </PersonalInfoContainerStyled>
  );
};

export { PersonalInfoPage };
