import { useAppLoading } from "contexts";
import { programActions } from "features/program/programSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { TStepResponse } from "model/program";
import { useProgramContext } from "pages/TrainingProgramDetailPage/ProgramContext/ProgramContext";
import { FC, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ProgramSectionItemRef } from "../ProgramSectionItem/ProgramSectionItem";
import {
  ProgramFreeSelectionItemStyled,
  ProgramSectionListStyled,
} from "./ProgramSectionList.styled";

const ProgramSectionList: FC = () => {
  const programData = useAppSelector((state) => state.program.programDetail);
  const loading = useAppSelector((state) => state.program.loading);
  const dispatch = useAppDispatch();

  const { startLoading, stopLoading } = useAppLoading();
  const { sectionRefs } = useProgramContext();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const programId = queryParams.get("programId");
  const memberId = queryParams.get("memberId");

  const programSectionListRef = useRef<HTMLDivElement>(null);
  const programFreeSelectionItemRef = useRef<HTMLDivElement | any>(null);

  const shumokuExtensions = {
    stepNumber: 5,
    stepName: "フリー選択",
    shumokus: programData?.shumokuExtensions || [],
  };

  useEffect(() => {
    dispatch(
      programActions.getProgramDetail({
        data: { programId: programId as string, memberId: memberId as string },
      })
    );
  }, []);

  useEffect(() => {
    if (loading) {
      startLoading();
    } else {
      stopLoading();
    }
    return stopLoading;
  }, [loading]);

  const freeSelectionHeight =
    programFreeSelectionItemRef.current?.getBoundingClientRect().height;

  useEffect(() => {
    const MARGIN_TOP = 278;
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const programFreeSelectionItemHeight =
        programFreeSelectionItemRef.current?.getBoundingClientRect().height;
      const paddingBottom =
        windowHeight - programFreeSelectionItemHeight - MARGIN_TOP;
      programFreeSelectionItemRef.current.style.marginBottom = `${paddingBottom}px`;
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [freeSelectionHeight]);

  return (
    <ProgramSectionListStyled ref={programSectionListRef}>
      {programData?.stepsResponse?.map((section: TStepResponse, index) => {
        return (
          <ProgramSectionItemRef
            ref={sectionRefs?.[index]}
            section={section}
            key={index}
          />
        );
      })}
      <ProgramFreeSelectionItemStyled ref={programFreeSelectionItemRef}>
        <ProgramSectionItemRef
          ref={sectionRefs?.[4]}
          section={shumokuExtensions}
          key={shumokuExtensions.stepNumber}
          isUpdate={true}
        />
      </ProgramFreeSelectionItemStyled>
    </ProgramSectionListStyled>
  );
};

export { ProgramSectionList };
