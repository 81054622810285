export type TQuestion = {
  id: string;
  name: string;
  type: EQuestionType;
  description?: string;
  order: number;
  category:
    | EGymQuestionCategory
    | EQuestionCategoryPhaseIntermediate
    | EJuniorQuestionCategory
    | EAdultQuestionCategory;
  isRequired?: boolean;
  isLocked?: boolean;
  dependQuestionId: string;
  dependAnswer: string;
  surveyCategory?: number;

  // Answer
  options?: TQuestionOption[];
  otherAnswer?: string;
  hasOther?: boolean;

  // For form handler, hot has in db
  disabled?: boolean;
};

export type TQuestionCreation = Omit<TQuestion, "options" | "id"> & {
  options?: string;
  phaseNumber: ESurveyPhase;
  answerChanges?: TAnswerChange[];
};

export type TAnswerChange = {
  questionId?: string;
  optionIndex?: number;
  action: string;
  old: string | null;
  new: string;
};

export type TQuestionOption = string | number;

export enum EQuestionType {
  SINGLE_SELECT,
  SINGLE_RADIO,
  MULTIPLE,
  TEXT,
  GENDER,
  AGE,
}

export enum EGymQuestionCategory {
  PERSONAL_INFO = "Personal Info",
  TRAINING_REQUEST = "Training Request",
  DIET = "Diet & injuries history",
  OTHER = "Other",
}

export enum EJuniorQuestionCategory {
  PERSONAL_INFO = "Personal Info",
  REQUESTS_TO_THE_SCHOOL = "Requests to the school",
  OTHER = "Other",
}

export enum EAdultQuestionCategory {
  PERSONAL_INFO = "Personal Info",
  REQUESTS_TO_THE_SCHOOL = "Requests to the school",
  ABOUT_YOUR_EXPERIENCE = "About your experience",
  OTHER = "Other",
}

export enum EQuestionCategoryPhaseIntermediate {
  TRAINING_REQUEST = "Training Request",
  INJURIES_HISTORY = "Injuries History",
  LAST_TRAINING_PROGRAM = "About last training program",
}

export type TQuestionForSubmit = {
  questionId: string;
  answer?: string;
  otherAnswer?: string | null;
  phaseNumber: number;
};

export type TOptionFormValue = {
  answer?: TQuestionOption[];
  otherAnswer?: string | null;
  checkedOther?: boolean;
};

export type TQuestionFormItem = {
  questionInfo: TQuestion;
  questionAnswer?: TOptionFormValue;
};

export type TQuestionCategory =
  | EGymQuestionCategory
  | EQuestionCategoryPhaseIntermediate
  | EJuniorQuestionCategory
  | EAdultQuestionCategory;

export type TSurveyValues = {
  [key in TQuestionCategory]?: TQuestionFormItem[];
};

export enum ESurveyCategory {
  GYM = 0,
  JUNIOR_SCHOOL = 1,
  ADULT_SCHOOL = 2,
}

export enum ESurveyPhase {
  GYM_PHASE_1 = 1,
  GYM_PHASE_2 = 2,
  JUNIOR_PHASE = 3,
  ADULT_PHASE = 4,
}

export type TSurveyPartCreation = {
  title: string;
  questions: TQuestion[];
  category: TQuestionCategory;
};

export type TSurveyCreation = {
  surveyPart: TSurveyPartCreation[];
};
