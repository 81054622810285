import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "configs/axiosInstance";
import dayjs from "dayjs";
import { ESurveyCategory } from "model/survey";
import { TResponse } from "types/service";

const DATE_FORMAT_API = "YYYY-MM-DD";
const GYM_SURVEY_QUESTIONS = [
  "アクトスで運動しようと思ったきっかけを教えてください。",
  "以前通っていたクラブを退会された理由について教えてください。(「はい」回答の方)",
  "スタッフのサポートを希望しますか?",
  "希望する場合のみ回答ください。",
  "アクトスで運動する目的を教えてください。",
  "1週間にどれくらいの頻度で通えそうですか?",
  "1日の運動時間はどれくらいを予定していますか？",
  "利用曜日はいつごろを予定していますか？",
];
const statisticApi = {
  getHistoryCheckInByDay: async () => {
    const url = "get-history-check-in-by-day";
    const config: AxiosRequestConfig = {
      params: { date: dayjs().format(DATE_FORMAT_API) },
    };

    const response: TResponse<THistoryCheckInRes> = await axiosInstance.get(
      url,
      config
    );

    return response;
  },

  getHistoryCheckInByMonth: async () => {
    const url = "get-history-check-in-by-month";
    const config: AxiosRequestConfig = {
      params: { date: dayjs().format(DATE_FORMAT_API) },
    };

    const response: TResponse<THistoryCheckInRes> = await axiosInstance.get(
      url,
      config
    );

    return response;
  },

  getCheckInTrendingThisWeek: async () => {
    const url = "get-history-check-in-by-week";
    const config: AxiosRequestConfig = {
      params: { date: dayjs().format(DATE_FORMAT_API) },
    };

    const response: TResponse<TCheckInTrendingRes> = await axiosInstance.get(
      url,
      config
    );

    return response;
  },

  getCheckInTrendingAllTime: async (data: TFilterDashboardForStoreRequest) => {
    const url = "get-history-check-in-by-week-trending";

    const response: TResponse<TCheckInTrendingRes> = await axiosInstance.post(
      url,
      data
    );

    return response;
  },

  getStatisticalSurveyForStore: async (
    filterRequest: TFilterDashboardForStoreRequest
  ) => {
    const apiRequests = GYM_SURVEY_QUESTIONS.map(async (questionName) => {
      const url = "get-statistical-questions-for-store";
      const requestPayload = { questionName, filterRequest };
      const response: TResponse<TStatisticalSurveyByQuestion> =
        await axiosInstance.post(url, requestPayload);

      if (response.succeeded) {
        const data = response.data;
        return getStatisticalSurveyByQuestionFromResponse(data);
      }
    });

    // Use Promise.all to wait for all requests to complete
    const results = await Promise.all(apiRequests);

    const validResults = results.filter(
      (result) => result !== null
    ) as TStatisticalSurveyByQuestion[];

    return validResults;
  },

  getHistoryQuestion: async (id: string) => {
    const url = "history-question";
    const config: AxiosRequestConfig = {
      params: { id },
    };
    const response: TResponse<THistoryQuestion[]> = await axiosInstance.get(
      url,
      config
    );
    return response;
  },

  getListChartQuestions: async (surveyCategory: ESurveyCategory) => {
    const url = "get-list-chart-questions";
    const config: AxiosRequestConfig = {
      params: { surveyCategory },
    };
    const response: TResponse<TChartQuestion[]> = await axiosInstance.get(
      url,
      config
    );
    return response;
  },

  getStatisticalSurveyByIdForHeadQuarter: async (
    filterRequest: TFilterDashboardRequest,
    ids: string[]
  ) => {
    const apiRequests = ids.map(async (questionId) => {
      const url = "get-statistical-questions-by-question-id";
      const requestPayload = { questionId, filterRequest };
      const response: TResponse<TStatisticalSurveyByQuestion> =
        await axiosInstance.post(url, requestPayload);
      if (response.succeeded) {
        return getStatisticalSurveyByQuestionFromResponse(response.data);
      }
    });

    // Use Promise.all to wait for all requests to complete
    const results = await Promise.all(apiRequests);
    const validResults = results.filter(
      (result) => result !== null
    ) as TStatisticalSurveyByQuestion[];

    return validResults;
  },

  getStatisticalSurveyForHeadQuarter: async (
    filterRequest: TFilterDashboardRequest
  ) => {
    const apiRequests = GYM_SURVEY_QUESTIONS.map(async (questionName) => {
      const url = "get-statistical-questions";
      const requestPayload = { questionName, filterRequest };
      const response: TResponse<TStatisticalSurveyByQuestion> =
        await axiosInstance.post(url, requestPayload);
      if (response.succeeded) {
        return getStatisticalSurveyByQuestionFromResponse(response.data);
      }
    });

    // Use Promise.all to wait for all requests to complete
    const results = await Promise.all(apiRequests);
    const validResults = results.filter(
      (result) => result !== null
    ) as TStatisticalSurveyByQuestion[];

    return validResults;
  },

  getTrainingStatusAnalysis: async (data: TFilterDashboardForStoreRequest) => {
    const url = "get-history-member-status";

    const response: TResponse<TTrainingStatusAnalysisRes> =
      await axiosInstance.post(url, data);

    return response;
  },

  getTrainingStatusAnalysisForHeadquarter: async (
    data: TFilterDashboardRequest
  ) => {
    const url = "get-history-member-status-with-axtos-headquarter";

    const response: TResponse<TTrainingStatusAnalysisRes> =
      await axiosInstance.post(url, data);

    return response;
  },

  getCheckInTrendingForHeadquarter: async (data: TFilterDashboardRequest) => {
    const url = "get-history-member-checkin-with-axtos-headquarter";

    const response: TResponse<TCheckInTrendingRes> = await axiosInstance.post(
      url,
      data
    );

    return response;
  },

  getPhaseStatusAnalysis: async (data: TFilterDashboardForStoreRequest) => {
    const url = "get-history-member-stage";

    const response: TResponse<TPhaseStatusAnalysisRes> =
      await axiosInstance.post(url, data);

    return response;
  },

  getPhaseStatusAnalysisForHeadquarter: async (
    data: TFilterDashboardRequest
  ) => {
    const url = "get-history-member-stage-with-axtos-headquarter";

    const response: TResponse<TPhaseStatusAnalysisRes> =
      await axiosInstance.post(url, data);

    return response;
  },
};

const getStatisticalSurveyByQuestionFromResponse = (
  data: TStatisticalSurveyByQuestion
) => {
  return {
    questionIds: data.questionIds,
    questionName: data.questionName,
    totalAnswer: data.totalAnswer,
    answers: data.answers.map((answer) => ({
      answerName: answer.answerName,
      quantityAnswer: answer.quantityAnswer,
      percentAnswer: answer.percentAnswer,
    })),
  };
};

type THistoryCheckInRes = {
  totalCheckIn: number;
  percent: number;
  checkInVolatility: number;
  status: number;
  date: string;
};

export type TEditHistoryResponse = {
  id?: string;
  questionId: string;
  createdAt: string;
  editContent: string;
};

export type TCheckInTrendingRes = {
  sun: number;
  mon: number;
  tue: number;
  wed: number;
  thu: number;
  fri: number;
  sat: number;
};

export type TStatisticalAnswerByQuestion = {
  answerName: string;
  quantityAnswer: number;
  percentAnswer: number;
};

export type TStatisticalSurveyByQuestion = {
  questionIds: string[];
  questionName: string;
  totalAnswer: number;
  answers: TStatisticalAnswerByQuestion[];
};

export type TChartQuestion = {
  id: string;
  name: string;
};

export type TQuestionChange = {
  action: "UPDATE" | "DELETE" | "ADD";
  object: "TITLE" | "ANSWER";
  old: string;
  new: string;
};

export type THistoryQuestion = {
  createdDate: string;
  changes: TQuestionChange[];
};

export type TTrainingStatusAnalysisRes = {
  totalMember: number;
  other: number;
  // Active
  memberActive: number;
  percentMemberActive: number;
  // Inactive 2 weeks
  memberInactiveInTwoWeek: number;
  percentMemberInactiveInTwoWeek: number;
  // Inactive 1 month
  memberInactiveInMonth: number;
  percentMemberInactiveInMonth: number;
};

export type TPhaseStatusAnalysisRes = {
  totalMember: number;
  other: number;
  // Phase 1
  memberBeginner: number;
  percentMemberBeginner: number;
  // Phase 2
  memberIntermediate: number;
  percentMemberIntermediate: number;
};

export type TFilterDashboardRequest = {
  fromDate?: string;
  toDate?: string;
  date?: string;
  time?: string;
  storeNumber: string[];
  surveyCategory?: number;
  gender: string;
  age: string[];
};

export type TFilterDashboardForStoreRequest = {
  fromDate?: string;
  toDate?: string;
  date?: string;
  gender: string;
  age: string[];
};

export type TFilterSurveyRequest = {
  fromDate?: string;
  toDate?: string;
  storeNumber: string[];
  surveyCategory?: number;
  phase: string;
};

export default statisticApi;
