import MESSAGES from "constants/messages";
import { showErrorNotification } from "helpers/notification";
import {
  TGeneratedProgramParams,
  TGetProgramParams,
  TProgramResponse,
  TProgramSurveyResponse,
  TSaveMemberShumokusCreationParams,
  TSaveProgramResponse,
  TSavedProgramParams,
  TUpdateMemberShumokuParams,
  TUpdatedProgramParams,
} from "model/program";
import { call, put, takeLatest } from "redux-saga/effects";
import { TAction } from "types/common";
import { TResponse, TResponseWithPagination } from "types/service";
import programApi, {
  TGetPublicProgramReq,
  TGetSurveyReq,
  TUpdateProgramForMemberReq,
} from "./programApi";
import { programActions } from "./programSlice";

export function* generateProgram(action: TAction<TGeneratedProgramParams>) {
  try {
    const response: TResponse<TProgramResponse> = yield call(
      programApi.generateProgram,
      action.payload.data
    );
    yield put(programActions.generateProgramSuccess({ data: response.data }));
  } catch (error) {
    showErrorNotification(MESSAGES["COM-MSG-002"]);
    yield put(programActions.generateProgramFailure());
  }
}

export function* saveProgram(action: TAction<TSavedProgramParams>) {
  try {
    const res: TResponse<TSaveProgramResponse> = yield call(
      programApi.saveProgram,
      action.payload.data
    );

    if (res.succeeded) {
      yield put(programActions.saveProgramSuccess());
      action.payload.onSuccess?.(res.data.id);
    }
  } catch (error) {
    showErrorNotification(MESSAGES["COM-MSG-002"]);
    yield put(programActions.saveProgramFailure());
  }
}

export function* saveMemberShumokusCreation(
  action: TAction<TSaveMemberShumokusCreationParams>
) {
  try {
    const res: TResponse = yield call(
      programApi.saveMemberShumokusCreation,
      action.payload.data
    );

    if (res.succeeded) {
      yield put(programActions.saveMemberShumokusCreationSuccess());
    }
  } catch (error) {
    showErrorNotification(MESSAGES["COM-MSG-002"]);
    yield put(programActions.saveMemberShumokusCreationFailure());
  }
}

export function* getProgramDetail(action: TAction<TGetProgramParams>) {
  try {
    const { programId, memberId } = action.payload.data;
    const response: TResponse<TProgramResponse> = yield call(
      programApi.getProgramDetail,
      programId,
      memberId
    );

    yield put(programActions.getProgramDetailSuccess({ data: response.data }));
  } catch (error) {
    showErrorNotification(MESSAGES["COM-MSG-002"]);
    yield put(programActions.getProgramDetailFailure());
  }
}

export function* updateProgram(action: TAction<TUpdatedProgramParams>) {
  try {
    yield call(programApi.updateProgram, action.payload.data.programMemberId, {
      programMemberId: action.payload.data.programMemberId,
      extensions: action.payload.data.extensions,
    });

    yield put(programActions.updateProgramSuccess());

    action.payload.onSuccess?.();
  } catch (error) {
    showErrorNotification(MESSAGES["COM-MSG-002"]);
    yield put(programActions.updateProgramFailure());
  }
}

export function* updateMemberShumoku(
  action: TAction<TUpdateMemberShumokuParams>
) {
  try {
    yield call(programApi.updateMemberShumoku, {
      memberId: action.payload.data.memberId,
      shumokuMasterId: action.payload.data.shumokuMasterId,
      weightLevel: action.payload.data.weightLevel,
      note: action.payload.data.note,
    });

    yield put(programActions.updateMemberShumokuSuccess());

    action.payload.onSuccess?.();
  } catch (error) {
    showErrorNotification(MESSAGES["COM-MSG-002"]);
    yield put(programActions.updateMemberShumokuFailure());
  }
}

export function* getPublicProgram(action: TAction<TGetPublicProgramReq>) {
  try {
    const response: TResponse<TProgramResponse> = yield call(
      programApi.getPublicProgram,
      action.payload.data.userId,
      action.payload.data.memberProgramId
    );

    yield put(programActions.getPublicProgramSuccess({ data: response.data }));
  } catch (error) {
    showErrorNotification(MESSAGES["COM-MSG-002"]);
    yield put(programActions.getPublicProgramFailure());
  }
}

export function* getProgramSurvey(action: TAction<TGetSurveyReq>) {
  try {
    const response: TResponseWithPagination<TProgramSurveyResponse> =
      yield call(
        programApi.getProgramSurvey,
        action.payload.data.memberAxtosId,
        action.payload.data.phaseNumber
      );

    yield put(programActions.getProgramSurveySuccess({ data: response.data }));
  } catch (error) {
    showErrorNotification(MESSAGES["COM-MSG-002"]);
    yield put(programActions.getProgramSurveyFailure());
  }
}

export function* updateProgramForMember(
  action: TAction<TUpdateProgramForMemberReq>
) {
  try {
    const response: TResponseWithPagination<TProgramResponse> = yield call(
      programApi.updateProgramForMember,
      action.payload.data
    );

    if (!response.succeeded) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
      yield put(programActions.updateProgramForMemberFailure());
    }

    action.payload.onSuccess?.();
    yield put(programActions.updateProgramForMemberSuccess());
  } catch (error) {
    showErrorNotification(MESSAGES["COM-MSG-002"]);
    yield put(programActions.updateProgramForMemberFailure());
  }
}

export const programWatcher = [
  takeLatest(programActions.generateProgram, generateProgram),
  takeLatest(programActions.saveProgram, saveProgram),
  takeLatest(
    programActions.saveMemberShumokusCreation,
    saveMemberShumokusCreation
  ),
  takeLatest(programActions.getProgramDetail, getProgramDetail),
  takeLatest(programActions.updateProgram, updateProgram),
  takeLatest(programActions.updateMemberShumoku, updateMemberShumoku),
  takeLatest(programActions.getPublicProgram, getPublicProgram),
  takeLatest(programActions.updateProgramForMember, updateProgramForMember),
  takeLatest(programActions.getProgramSurvey, getProgramSurvey),
];
