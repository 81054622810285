import { Col, Row } from "antd";
import { ShumokuItem } from "components";
import { TShumoku } from "model/program";
import { FC } from "react";
import { ProgramVideoListStyled } from "./ProgramVideoList.styled";

type TProps = {
  videos: TShumoku[];
  type?: string;
  isUpdate?: boolean;
};

const ProgramVideoList: FC<TProps> = ({ videos, type, isUpdate }) => {
  return (
    <ProgramVideoListStyled>
      <Row gutter={[20, 24]}>
        {videos.map((video, index) => (
          <Col xs={24} md={12} xl={8} key={index}>
            <ShumokuItem
              type={type}
              data={video}
              orderNumber={String(index + 1)}
              isUpdate={isUpdate}
              isPublic={true}
            />
          </Col>
        ))}
      </Row>
    </ProgramVideoListStyled>
  );
};
export { ProgramVideoList };
