import { Button } from "antd";
import { BodyIndexModal } from "components";
import images from "constants/images";
import { MEMBER_TRANSLATE } from "pages/MemberDetailPage/MemberDetailPage.translate";
import { useMemberDetailPageContext } from "pages/MemberDetailPage/contexts";
import {
  ActionWrapperStyled,
  AppLogoStyled,
  InfoWrapperStyled,
  InkImgStyled,
  MemberIdStyled,
} from "./MemberInfo.styled";
import { ButtonCheckIn, MeasurementInfo } from "./components";
import { SelectSurveyDetailModal } from "components/SelectSurveyModal/SelectSurveyDetailModal";
import { EMemberActionStatus } from "../MemberStatistic/components";
import { ESurveyCategory, ESurveyPhase } from "model/survey";

function MemberInfo() {
  const {
    totalCheckInTimes,
    bodyIndex,
    memberDetail,
    memberId,
    loading,
    hasError,
    memberAction,
    handleUpdateBodyIndex,
    handleCheckInSuccess,
    handleViewSurvey,
  } = useMemberDetailPageContext();

  let gymSurveyList =
    memberDetail?.phaseResponses?.filter(
      (response) => response.surveyCategory === ESurveyCategory.GYM
    ) || [];

  if (memberAction === EMemberActionStatus.NEED_DO_SURVEY_PHASE_2) {
    gymSurveyList = [
      ...gymSurveyList,
      {
        phaseNumber: ESurveyPhase.GYM_PHASE_2,
      },
    ];
  }

  if (memberAction === EMemberActionStatus.NEED_DO_SURVEY_GYM) {
    gymSurveyList = [
      ...gymSurveyList,
      {
        phaseNumber: ESurveyPhase.GYM_PHASE_1,
      },
    ];
  }

  const juniorSurvey = memberDetail?.phaseResponses?.find(
    (response) => response.surveyCategory === ESurveyCategory.JUNIOR_SCHOOL
  ) || { phaseNumber: ESurveyPhase.JUNIOR_PHASE };

  const adultSurvey = memberDetail?.phaseResponses?.find(
    (response) => response.surveyCategory === ESurveyCategory.ADULT_SCHOOL
  ) || { phaseNumber: ESurveyPhase.ADULT_PHASE };

  if (loading || hasError) {
    return (
      <InfoWrapperStyled>
        <InkImgStyled />
        <AppLogoStyled src={images.AppLogoSecondary} alt="logo" />
      </InfoWrapperStyled>
    );
  }

  return (
    <InfoWrapperStyled>
      <InkImgStyled />
      <AppLogoStyled src={images.AppLogoSecondary} alt="logo" />

      <MemberIdStyled>
        会員ID
        <br />
        {memberDetail?.memberAxtosId}
      </MemberIdStyled>

      <ButtonCheckIn
        className="btn-check-in"
        trainingPrograms={memberDetail?.programs}
        memberId={memberId}
        onCheckInSuccess={handleCheckInSuccess}
      />

      <p className="total-check-in-date">
        トレーニング回数：{totalCheckInTimes}回
      </p>

      <MeasurementInfo className="measurement-info" data={bodyIndex} />

      <ActionWrapperStyled>
        <SelectSurveyDetailModal
          gymSurveyList={gymSurveyList}
          juniorSurvey={juniorSurvey}
          adultSurvey={adultSurvey}
          onSelect={handleViewSurvey}
          autoSelect
        >
          <Button block size="large">
            {MEMBER_TRANSLATE.TITLE_VIEW_SURVEY}
          </Button>
        </SelectSurveyDetailModal>

        <BodyIndexModal
          onSubmit={handleUpdateBodyIndex}
          title={MEMBER_TRANSLATE.TITLE_EDIT_BODY_INDEX}
          initialValues={bodyIndex}
        >
          <Button block size="large">
            {MEMBER_TRANSLATE.BTN_EDIT_BODY_INDEX}
          </Button>
        </BodyIndexModal>
      </ActionWrapperStyled>
    </InfoWrapperStyled>
  );
}

export { MemberInfo };
