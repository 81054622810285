import { FormInstance } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DEFAULT_GUID, DEFAULT_MODAL_PROPS } from "constants/common";
import MESSAGES from "constants/messages";
import { useAppLoading, useAppLocation, useAppModal } from "contexts";
import surveyApi from "features/survey/surveyApi";
import { showErrorNotification } from "helpers/notification";
import {
  getSurveyDataForEditingMode,
  mappingSurveyMasterForSubmitting,
} from "helpers/survey";
import {
  EGymQuestionCategory,
  EQuestionType,
  ESurveyPhase,
  TAnswerChange,
  TQuestion,
  TQuestionCategory,
  TSurveyCreation,
  TSurveyPartCreation,
} from "model/survey";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { PersonalInfoRoute, TSurveyManagementParams } from "routers/router";
import { ValidateErrorEntity } from "types/antd";
import { TString } from "types/common";
import {
  SURVEY_CREATION_FORM,
  initialQuestion,
} from "../SurveyManagementPage.constant";

type TReorderQuestionParams = {
  partIndex: number;
  oldIndex: number;
  newIndex: number;
};

type TQuestionIndex = {
  partIndex: number;
  questionIndex: number;
};

type TSurveyManagementValues = {
  surveyForm?: FormInstance<TSurveyCreation>;
  addSurveyPart: () => void;
  onSaveSurvey: (values: TSurveyCreation) => void;
  onFinishSurveyFailed: (
    errorInfo: ValidateErrorEntity<TSurveyCreation>
  ) => void;
  onChangePart: (key: string) => void;
  surveyPartNameList: string[];
  activePartTab: string;
  onUpdateSurveyPartName: (index: number, value: string) => void;

  // question
  duplicateQuestion: (partIndex: number, questionIndex: number) => void;
  addNewQuestion: (partIndex: number) => void;
  activeQuestionIndex: number;
  updateActiveQuestionIndex: (index: number) => void;
  onReorderQuestion: (params: TReorderQuestionParams) => void;
  genQuestionWrapperId: (partIndex: number, questionIndex: number) => string;
  questionErrorList: TQuestionIndex[];
  oldOptionValue: string | null;
  setOldOptionValue: (value: string | null) => void;
  answerChangeAll: TAnswerChange[];
  setAnswerChangeAll: (value: TAnswerChange[]) => void;
  handleAnswerChange: (change: TAnswerChange) => void;
};

const initialValues: TSurveyManagementValues = {
  surveyForm: undefined,
  addSurveyPart: () => {
    //
  },
  surveyPartNameList: [],
  onSaveSurvey: (_values) => {
    //
  },
  onFinishSurveyFailed: (_errorInfo) => {
    //
  },
  activePartTab: "",
  onChangePart: (_key) => {
    //
  },
  onUpdateSurveyPartName: (_index, _value) => {
    //
  },
  duplicateQuestion: (_partIndex, _questionIndex) => {
    //
  },
  addNewQuestion: (_partIndex) => {
    //
  },
  activeQuestionIndex: 0,
  updateActiveQuestionIndex(_index) {
    //
  },
  onReorderQuestion: (_params) => {
    //
  },
  genQuestionWrapperId: (_partIndex, _questionIndex) => "",
  questionErrorList: [],
  oldOptionValue: null,
  setOldOptionValue: (_value) => {
    //
  },
  answerChangeAll: [],
  setAnswerChangeAll: (_value) => {
    //
  },
  handleAnswerChange: (_change) => {
    //
  },
};

const SurveyManagementPageContext =
  createContext<TSurveyManagementValues>(initialValues);

const useSurveyManagementPageContext = () =>
  useContext(SurveyManagementPageContext);

function SurveyManagementPageContextProvider(props: PropsWithChildren) {
  const { children } = props;

  const [surveyPartNameList, setSurveyPartNameList] = useState<string[]>([]);
  const [activePartTab, setActivePartTab] = useState("0");
  const [surveyForm] = useForm<TSurveyCreation>();
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [questionErrorList, setQuestionErrorList] = useState<TQuestionIndex[]>(
    []
  );

  const { phaseNumber = "1" } = useParams<TString<TSurveyManagementParams>>();
  const { startLoading, stopLoading } = useAppLoading();
  const { appModal, showModalSuccess } = useAppModal();
  const { backToPrevPage } = useAppLocation();
  const [oldOptionValue, setOldOptionValue] = useState<string | null>(null);
  const [answerChangeAll, setAnswerChangeAll] = useState<TAnswerChange[]>([]);

  const handleAnswerChange = (answerChange: TAnswerChange) => {
    if (answerChange.old !== answerChange.new) {
      if (answerChange.action === "DELETE" && answerChange.old === "OTHER") {
        setAnswerChangeAll((prevChanges: TAnswerChange[]) =>
          prevChanges.filter(
            (change) =>
              !(
                change.questionId === answerChange.questionId &&
                change.new === "OTHER"
              )
          )
        );
        return;
      }
      setAnswerChangeAll((prevChanges) => {
        const existingIndex = prevChanges.findIndex(
          (change) =>
            change.questionId === answerChange.questionId &&
            change.optionIndex === answerChange.optionIndex
        );

        if (existingIndex !== -1) {
          // Update the existing change
          const updatedChanges = [...prevChanges];
          updatedChanges[existingIndex] = {
            ...updatedChanges[existingIndex],
            new: answerChange.new,
            action: answerChange.action,
          };
          return updatedChanges;
        } else {
          // Add new change
          return [...prevChanges, answerChange];
        }
      });
    }
    // Remove elements where old === new
    setAnswerChangeAll((prevChanges: TAnswerChange[]) =>
      prevChanges
        .filter((change) => change.old !== change.new)
        .map((change) =>
          change.old === "" ? { ...change, action: "ADD" } : change
        )
    );
    setOldOptionValue(null);
  };

  // Handlers
  const scrollIntoQuestion = (
    partIndex: number,
    questionIndex: number,
    options?: ScrollIntoViewOptions
  ) => {
    const questionInstance = document.getElementById(
      genQuestionWrapperId(partIndex, questionIndex)
    );

    questionInstance?.scrollIntoView({
      block: "center",
      behavior: "smooth",
      ...options,
    });
  };

  const addSurveyPart = () => {
    const surveyPart: TSurveyPartCreation[] =
      surveyForm.getFieldValue(SURVEY_CREATION_FORM.SURVEY_PART.NAME) || [];

    const _surveyPartList: TSurveyPartCreation[] = [
      ...surveyPart,
      {
        title: `Survey part ${surveyPart.length + 1}`,
        questions: [initialQuestion],
        category: EGymQuestionCategory.OTHER,
      },
    ];

    // Update survey part list in form and state
    surveyForm.setFieldValue(
      SURVEY_CREATION_FORM.SURVEY_PART.NAME,
      _surveyPartList
    );
    setSurveyPartNameList(_surveyPartList.map((part) => part.title));

    // Focus to the new tab
    setActivePartTab(surveyPart.length.toString());
    setActiveQuestionIndex(0);
  };

  const onChangePart = (key: string) => {
    setActivePartTab(key);
    setActiveQuestionIndex(0);

    // Scroll to first question
    setTimeout(() => {
      scrollIntoQuestion(Number(key), 0, { behavior: "auto" });
    }, 0);
  };

  const onSaveSurvey = (values: TSurveyCreation) => {
    async function saveSurvey() {
      try {
        startLoading();
        const mappedQuestions = mappingSurveyMasterForSubmitting({
          phaseNumber: Number(phaseNumber),
          surveyPartList: values.surveyPart,
          answerChangeAll: answerChangeAll,
        });

        const { succeeded } = await surveyApi.updateSurveyMaster(
          mappedQuestions
        );

        if (succeeded) {
          showModalSuccess({
            title: "回答を変更しました。",
            okText: "設定管理",
            onOk: () => {
              backToPrevPage(PersonalInfoRoute.path);
            },
          });
        }
      } catch (e) {
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      } finally {
        stopLoading();
      }
    }

    appModal.confirm({
      ...DEFAULT_MODAL_PROPS.CONFIRM,
      title: "質問内容を変更しますか？",
      onOk: () => saveSurvey(),
    });
  };

  const onFinishSurveyFailed = (
    errorInfo: ValidateErrorEntity<TSurveyCreation>
  ) => {
    const errorNameList = errorInfo.errorFields
      .sort((a, b) => {
        if (a.name[1] === b.name[1]) {
          return Number(a.name[3]) - Number(b.name[3]);
        }
        return Number(a.name[1]) - Number(b.name[1]);
      })
      .map((e) => e.name);

    const firstFieldError = errorNameList[0];

    const [_parts, partIndex, _questions, questionIndex] = firstFieldError;
    setActivePartTab(partIndex.toString());
    setActiveQuestionIndex(Number(questionIndex));
    setTimeout(() => {
      scrollIntoQuestion(Number(partIndex), Number(questionIndex));
    }, 0);

    // Update error list
    const _questionErrorList: TQuestionIndex[] = errorNameList.map(
      (errorName) => {
        const [_parts, partIndex, _questions, questionIndex] = errorName;
        return {
          partIndex: Number(partIndex),
          questionIndex: Number(questionIndex),
        };
      }
    );
    setQuestionErrorList(_questionErrorList);
  };

  const onUpdateSurveyPartName = (index: number, value: string) => {
    setSurveyPartNameList((prevState) => [
      ...prevState.slice(0, index),
      value,
      ...prevState.slice(index + 1),
    ]);
  };

  const duplicateQuestion = (partIndex: number, questionIndex: number) => {
    const questionList: TQuestion[] =
      surveyForm.getFieldValue([
        SURVEY_CREATION_FORM.SURVEY_PART.NAME,
        partIndex,
        SURVEY_CREATION_FORM.SURVEY_PART_QUESTIONS.NAME,
      ]) || [];
    const questionItemInfo = { ...questionList[questionIndex] };
    const formattedQuestion: TQuestion = {
      ...questionItemInfo,
      id: "",
      options:
        questionItemInfo.type === EQuestionType.TEXT
          ? []
          : questionItemInfo.options,
      dependAnswer: "",
      dependQuestionId: DEFAULT_GUID,
    };

    surveyForm.setFieldValue(
      [
        SURVEY_CREATION_FORM.SURVEY_PART.NAME,
        partIndex,
        SURVEY_CREATION_FORM.SURVEY_PART_QUESTIONS.NAME,
      ],
      [
        ...questionList.slice(0, questionIndex + 1),
        formattedQuestion,
        ...questionList.slice(questionIndex + 1),
      ]
    );

    setTimeout(() => {
      scrollIntoQuestion(partIndex, questionIndex + 1);
    }, 0);
  };

  const addNewQuestion = (partIndex: number) => {
    const questionList: TQuestion[] =
      surveyForm.getFieldValue([
        SURVEY_CREATION_FORM.SURVEY_PART.NAME,
        partIndex,
        SURVEY_CREATION_FORM.SURVEY_PART_QUESTIONS.NAME,
      ]) || [];
    const category: TQuestionCategory = surveyForm.getFieldValue([
      SURVEY_CREATION_FORM.SURVEY_PART.NAME,
      partIndex,
      SURVEY_CREATION_FORM.SURVEY_PART_CATEGORY.NAME,
    ]);

    surveyForm.setFieldValue(
      [
        SURVEY_CREATION_FORM.SURVEY_PART.NAME,
        partIndex,
        SURVEY_CREATION_FORM.SURVEY_PART_QUESTIONS.NAME,
      ],
      [...questionList, { ...initialQuestion, category }]
    );

    setActiveQuestionIndex(questionList.length);

    // Scroll to the new question
    setTimeout(() => {
      scrollIntoQuestion(partIndex, questionList.length);
    }, 0);
  };

  const onReorderQuestion = ({
    newIndex,
    oldIndex,
    partIndex,
  }: TReorderQuestionParams) => {
    const questionList: TQuestion[] =
      surveyForm.getFieldValue([
        SURVEY_CREATION_FORM.SURVEY_PART.NAME,
        partIndex,
        SURVEY_CREATION_FORM.SURVEY_PART_QUESTIONS.NAME,
      ]) || [];
    const reorderQuestion = questionList[oldIndex];
    const filteredQuestionList: TQuestion[] = questionList.filter(
      (_, index) => index !== oldIndex
    );

    surveyForm.setFieldValue(
      [
        SURVEY_CREATION_FORM.SURVEY_PART.NAME,
        partIndex,
        SURVEY_CREATION_FORM.SURVEY_PART_QUESTIONS.NAME,
      ],
      [
        ...filteredQuestionList.slice(0, newIndex),
        reorderQuestion,
        ...filteredQuestionList.slice(newIndex),
      ]
    );

    setActiveQuestionIndex(newIndex);

    // Update error question list if needed
    setQuestionErrorList((prevQuestionList) => {
      return prevQuestionList.map((q) => {
        if (q.partIndex === partIndex && q.questionIndex === oldIndex) {
          return { ...q, questionIndex: newIndex };
        }
        return q;
      });
    });
  };

  const getInitialSurvey = async (phase: number) => {
    try {
      startLoading();
      const { data, succeeded } = await surveyApi.getSurveyQuestions(phase);
      if (succeeded && data) {
        const initialSurvey = getSurveyDataForEditingMode({
          surveyPhase: phase as ESurveyPhase,
          questions: data,
        });

        surveyForm.setFieldValue(
          SURVEY_CREATION_FORM.SURVEY_PART.NAME,
          initialSurvey
        );
        setSurveyPartNameList(initialSurvey.map((s) => s.title));
      }
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  const genQuestionWrapperId = (partIndex: number, questionIndex: number) => {
    return `wrapper_part_${partIndex}_question_${questionIndex}`;
  };

  const updateActiveQuestionIndex = (questionIndex: number) => {
    const isLockedQuestion = surveyForm.getFieldValue([
      SURVEY_CREATION_FORM.SURVEY_PART.NAME,
      activePartTab,
      SURVEY_CREATION_FORM.SURVEY_PART_QUESTIONS.NAME,
      questionIndex,
      SURVEY_CREATION_FORM.IS_LOCKED.NAME,
    ]);

    // No active locked question
    if (isLockedQuestion) return;

    // Update active question index
    setActiveQuestionIndex(questionIndex);
  };

  // Effects
  useEffect(() => {
    getInitialSurvey(Number(phaseNumber));
  }, [phaseNumber]);

  return (
    <SurveyManagementPageContext.Provider
      value={{
        // Survey form controls
        surveyForm,
        onSaveSurvey,
        onFinishSurveyFailed,

        // Survey part
        addSurveyPart,
        surveyPartNameList,
        activePartTab,
        onChangePart,
        onUpdateSurveyPartName,

        // Question
        duplicateQuestion,
        addNewQuestion,
        activeQuestionIndex,
        updateActiveQuestionIndex,
        onReorderQuestion,
        genQuestionWrapperId,
        questionErrorList,
        oldOptionValue,
        setOldOptionValue,
        answerChangeAll,
        setAnswerChangeAll,
        handleAnswerChange,
      }}
    >
      {children}
    </SurveyManagementPageContext.Provider>
  );
}

export { SurveyManagementPageContextProvider, useSurveyManagementPageContext };
