import {
  MAX_WEIGHT_LEVEL,
  MIN_WEIGHT_LEVEL,
  MULTIPLE_WEIGHT_LEVEL,
} from "./common";

const MESSAGES = {
  // ================== Common message ==================
  /**
   * Required form item
   */
  "COM-MSG-001": (fieldName: string) => `${fieldName}を入力してください。`,
  /**
   * Something wrong
   */
  "COM-MSG-002":
    "リクエストの処理中に問題が発生しました。もう一度お試しください。",
  /**
   * {field name} must be fixed {number} digits
   */
  "COM-MSG-003": (fieldName: string, number: number) =>
    `${fieldName}の桁数は必ず${number}桁で入力してください。`,
  /**
   * You do not have access to store member data that you do not manage.
   */
  "COM-MSG-004":
    "自分が管理していない店番の会員情報を閲覧する権限がありません。",

  // ================== Message for each screen ==================
  // ================== SCR-W01
  "SCR-W01-MSG-001": "アカウントが存在しません。",
  "SCR-W01-MSG-002": "パスワードが正しくありません。",

  // ================== SCR-W02
  "SCR-W02-MSG-001": "会員が見つかりませんでした。",

  // ================== SCR-W04
  "SCR-W04-MSG-001": "メールアドレスが存在しません。",

  // ================== SCR-W05
  "SCR-W05-MSG-001":
    "パスワードを設定するリクエストの有効期限が切れましたので、パスワードを設定するリクエストをリセットしてください。",
  "SCR-W05-MSG-002": "パスワードの確認はパスワードと一致しなければなりません。",

  // ================== SCR-W10
  "SCR-W10-MSG-001-TITLE": "ログアウト",
  "SCR-W10-MSG-001-CONTENT": "ログアウトしますか？",

  // ================== SCR-W16
  "SCR-W16-MSG-001": "トレーニングメニューを作成しました！",
  "SCR-W16-MSG-002": "トレーニングメニューを更新しました！",

  // Weight level validate
  "MIN-MAX-WEIGHT-LEVEL": `${MIN_WEIGHT_LEVEL} ~ ${MAX_WEIGHT_LEVEL}の範囲で重量を入力してください。`,
  "MULTIPLE-WEIGHT-LEVEL": `${MULTIPLE_WEIGHT_LEVEL}の倍数で重量を入力してください。`,
};

export default MESSAGES;
