import { EQuestionType, TQuestion, TQuestionOption } from "model/survey";
import { useMemo } from "react";
import { QuestionTitleStyled } from "./Question.styled";
import {
  OptionsMultiple,
  OptionsRadio,
  OptionsSingleSelectBox,
  TextArea,
} from "./components";
import { TValueForFormAntd } from "types/antd";

export const PLACE_HOLDER_TEXT = "回答を入力してください";
const AREA_INVALID = "aria-invalid";
const AREA_REQUIRED = "aria-required";
const TRUE_VALUE = "true";

type TQuestionProps<V = any, P = any> = {
  data: TQuestion;
  index?: string | number;
  id?: any;
  disabled?: boolean;
  [AREA_INVALID]?: string; // Antd prop
  [AREA_REQUIRED]?: string; // Antd prop
} & TValueForFormAntd<V, P>;

export type TOptionsProps<V = any, P = any> = {
  options: TQuestionOption[];
  hasOther?: boolean;
} & TValueForFormAntd<V, P>;

function Question(props: TQuestionProps) {
  const {
    value,
    onChange,
    data,
    index = null,
    id,
    readOnly,
    disabled,
    [AREA_REQUIRED]: areaRequired,
    [AREA_INVALID]: areaInvalid,
  } = props;
  const { name: title, options: options = [], type, hasOther } = data;

  const hasError = useMemo(() => {
    return areaInvalid === TRUE_VALUE;
  }, [areaInvalid]);

  const renderOptionDefaultProps = {
    value,
    onChange,
    readOnly,
    disabled,
  };

  const renderOptions =
    type === EQuestionType.SINGLE_SELECT ? (
      <OptionsSingleSelectBox options={options} {...renderOptionDefaultProps} />
    ) : type === EQuestionType.SINGLE_RADIO ? (
      <OptionsRadio
        options={options}
        hasOther={hasOther}
        {...renderOptionDefaultProps}
      />
    ) : type === EQuestionType.MULTIPLE ? (
      <OptionsMultiple
        options={options}
        hasOther={hasOther}
        {...renderOptionDefaultProps}
      />
    ) : type === EQuestionType.TEXT ? (
      <TextArea {...renderOptionDefaultProps} />
    ) : type === EQuestionType.AGE ? (
      <OptionsRadio options={options} {...renderOptionDefaultProps} smallSize />
    ) : type === EQuestionType.GENDER ? (
      <OptionsRadio options={options} {...renderOptionDefaultProps} smallSize />
    ) : null;

  return (
    // !add prop id to make scrollToFirstError and scrollToField work with custom form control
    <div id={id}>
      <QuestionTitleStyled className={hasError ? "has-error" : ""}>
        <div className="question-number-wrapper">
          <div className="question-number">{index !== null && `${index}`}</div>
        </div>

        <div className="question-content">
          {title}
          <span style={{ color: "#CF1322" }}>
            {areaRequired === TRUE_VALUE && "*"}
          </span>
        </div>
      </QuestionTitleStyled>

      {renderOptions}
    </div>
  );
}

export { Question };
