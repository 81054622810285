import images from "constants/images";
import { PATHNAME_LIST } from "constants/pathname";
import { ArrowLeftIcon, ArrowLeftShortIcon } from "constants/svgs";
import { useAppLocation } from "contexts";
import { useNavigate, useParams } from "react-router-dom";
import { SurveyRoute, TMemberAxtosParams } from "routers/router";
import {
  ActionsWrapperStyled,
  AppLogoStyled,
  ButtonBackStyled,
  ButtonLinkStyled,
  MemberIdStyled,
  MemberNewbieContainerStyled,
} from "./MemberNewbie.styled";
import { SelectSurveyNewbieModal } from "components";
import { PERSONAL_INFORMATION_TRANSLATE } from "pages/PersonalInfoPage/PersonalInfoPage.translate";
import { ESurveyPhase } from "model/survey";
import {
  ADULT_SCHOOL_SURVEY,
  GYM_SURVEY,
  JUNIOR_SCHOOL_SURVEY,
} from "constants/common";

function MemberNewbiePage() {
  const { memberAxtosId = "" } = useParams<TMemberAxtosParams>();

  const navigate = useNavigate();
  const { backToPrevPage } = useAppLocation();

  const handleBackPage = () => {
    backToPrevPage(PATHNAME_LIST.MEMBER_CONFIRMATION);
  };

  const navigateToSurvey = (surveyPhase: ESurveyPhase) => {
    navigate(
      SurveyRoute.genPath({
        phaseNumber: surveyPhase,
        memberAxtosId: memberAxtosId,
      }),
      { replace: true }
    );
  };

  return (
    <MemberNewbieContainerStyled>
      <div className="overlay-bg">
        <ButtonBackStyled onClick={handleBackPage}>
          <ArrowLeftIcon /> 戻る
        </ButtonBackStyled>

        <AppLogoStyled src={images.AppLogoSecondary} />

        <MemberIdStyled>
          <span className="id-title">会員ID</span>
          <span className="member-id">{memberAxtosId}</span>
        </MemberIdStyled>

        <ActionsWrapperStyled>
          <SelectSurveyNewbieModal
            onSelect={navigateToSurvey}
            surveyList={[
              {
                phaseNumber: ESurveyPhase.GYM_PHASE_1,
                phaseName: GYM_SURVEY,
              },
              {
                phaseNumber: ESurveyPhase.ADULT_PHASE,
                phaseName: ADULT_SCHOOL_SURVEY,
              },
              {
                phaseNumber: ESurveyPhase.JUNIOR_PHASE,
                phaseName: JUNIOR_SCHOOL_SURVEY,
              },
            ]}
            modalTitle={
              PERSONAL_INFORMATION_TRANSLATE.SELECT_SURVEY_MODAL_NEWBIE_TITLE
            }
          >
            <ButtonLinkStyled>
              <img className="action-icon" src={images.CheckListImage} />
              <p className="action-name">アンケート実施</p>
              <ArrowLeftShortIcon className="action-right" />
            </ButtonLinkStyled>
          </SelectSurveyNewbieModal>
        </ActionsWrapperStyled>
      </div>
    </MemberNewbieContainerStyled>
  );
}

export { MemberNewbiePage };
