import { Col, Row } from "antd";
import { ShumokuItemCreation } from "components";
import { useAppSelector } from "hooks";
import { TShumoku } from "model/program";
import { FC } from "react";
import { FreeSelectComponent } from "../FreeSelectComponent/FreeSelectComponent";
import { ProgramVideoListStyled } from "./ProgramVideoList.styled";

type TProps = {
  videos: TShumoku[];
  type?: string;
  isUpdate?: boolean;
};

const ProgramVideoList: FC<TProps> = ({ videos, type, isUpdate }) => {
  const { program, extensions } = useAppSelector((state) => ({
    program: state.program.generatedProgram,
    extensions: state.program.extensions,
  }));

  const componentsArray = Array.from(
    { length: program?.numOfFreeSelection as number },
    (_, index) => <FreeSelectComponent key={index} freeId={index} />
  );

  const filterComponents = componentsArray?.map((item, index) => {
    const foundedItem = extensions.find(
      (item) => (item as TShumoku & { freeId: number }).freeId === index
    );

    if (foundedItem) {
      return (
        <Col xs={24} md={12} xl={8} key={index}>
          <ShumokuItemCreation
            isUpdate={isUpdate}
            data={foundedItem}
            orderNumber={String(index + 1)}
            isExtensionComponent={true}
            freeId={index}
          />
        </Col>
      );
    } else {
      return item;
    }
  });

  if (type === "free") {
    return (
      <ProgramVideoListStyled>
        <Row gutter={[20, 24]}>{filterComponents}</Row>
      </ProgramVideoListStyled>
    );
  }

  return (
    <ProgramVideoListStyled>
      <Row gutter={[20, 24]}>
        {videos.map((video, index) => (
          <Col xs={24} md={12} xl={8} key={index}>
            <ShumokuItemCreation
              data={video}
              isUpdate={isUpdate}
              orderNumber={String(index + 1)}
            />
          </Col>
        ))}
        {componentsArray.length > 0 && isUpdate && componentsArray}
      </Row>
    </ProgramVideoListStyled>
  );
};
export { ProgramVideoList };
