import { Pie, PieConfig } from "@ant-design/plots";
import {
  LegendItemStyled,
  LegendStyled,
  SurveyStatisticDonutChartWrapperStyled,
  UnstyledList,
} from "./SurveyStatisticDonutChart.styled";
import { numberWithCommas } from "helpers";
import statisticApi, {
  THistoryQuestion,
  TQuestionChange,
  TStatisticalSurveyByQuestion,
} from "features/statistic/statisticApi";
import { memo, useState } from "react";
import { NUMBER_OF_DECIMAL_PLACES } from "constants/common";
import { Empty, Modal, Table } from "antd";
import {
  ModalContentStyled,
  ListItemStyled,
} from "./SurveyStatisticDonutChart.styled";
import { useAppLoading } from "contexts";
import { showErrorNotification } from "helpers/notification";
import MESSAGES from "constants/messages";

const TEXT_TRANSLATION = {
  EDIT_HISTORY: "編集履歴",
  DATE_COLUMN: "日付",
  EDIT_CONTENT: "内容の変更",
};

function SurveyStatisticDonutChartInternal(
  props: TStatisticalSurveyByQuestion
) {
  const colors = [
    "#99CAF8",
    "#FA541C",
    "#FFC62D",
    "#85BF64",
    "#27BEB8",
    "#2D9596",
    "#9AD0C2",
    "#F1FADA",
  ];

  const filteredColorForChartConfigs = props.answers.map((answer, index) => ({
    answerName: answer.answerName,
    color: colors[index % colors.length],
  }));

  const colorForLegendConfigs = colors.map((item) => item);
  const [isModalHistoryOpen, setIsModalHistoryOpen] = useState(false);
  const { startLoading, stopLoading } = useAppLoading();
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState<THistoryQuestion[]>([]);

  const handleCancel = () => {
    setIsModalHistoryOpen(false);
    setHistoryData([]);
  };

  const showModalHistory = async () => {
    setIsModalHistoryOpen(true);
    setLoading(true);
    try {
      startLoading();
      const response = await statisticApi.getHistoryQuestion(
        props.questionIds[0]
      );
      setHistoryData(response.data);
    } catch (error) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
      setLoading(false);
    }
  };

  const getFormattedMessage = (change: TQuestionChange) => {
    const { object, action, old: oldValue, new: newValue } = change;

    const messageMap: Record<
      TQuestionChange["object"],
      Partial<Record<TQuestionChange["action"], string>>
    > = {
      TITLE: {
        UPDATE: `質問内容は 「${oldValue}」から 「${newValue}」に修正しました。`,
      },
      ANSWER: {
        UPDATE: `「${oldValue}」は 「${newValue}」に修正しました。`,
        DELETE: `回答は「${oldValue}」を削除しました。`,
        ADD: `回答は「${newValue}」を追加しました。`,
      },
    };

    return messageMap[object]?.[action] || "";
  };

  const columnsTableEditHistory = [
    {
      title: TEXT_TRANSLATION.DATE_COLUMN,
      dataIndex: "createdDate",
      key: "createdDate",
      width: 165,
      render: (date: string) => {
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        };
        return new Intl.DateTimeFormat("ja-JP", options).format(new Date(date));
      },
    },
    {
      title: TEXT_TRANSLATION.EDIT_CONTENT,
      dataIndex: "changes",
      key: "changes",
      render: (changes: TQuestionChange[]) => {
        return (
          <UnstyledList>
            {changes.map((change, index) => {
              const formatMessage = getFormattedMessage(change);
              return (
                <ListItemStyled key={index}>{formatMessage}</ListItemStyled>
              );
            })}
          </UnstyledList>
        );
      },
    },
  ];

  const config: PieConfig = {
    data: props.answers,
    height: 169,
    width: 169,
    angleField: "quantityAnswer",
    colorField: "answerName", // Set to "answerName" to distinguish different answers
    radius: 1,
    innerRadius: 0.6,
    label: false,
    statistic: {
      title: false,
      content: {
        customHtml: () => {
          const displayValue = numberWithCommas(props.totalAnswer);
          // Auto wrap line when `displayValue` has more than 3 digits
          return `<div class="member-statistic-content ${
            displayValue.length >= 3 ? "wrap-line" : ""
          }">
            <span class="total-member" style="padding: 0">${displayValue}</span><span class="unit" style="display: inline">人</span>
          </div>`;
        },
      },
    },
    tooltip: false,
    color: filteredColorForChartConfigs.map((item) => item.color),
    legend: false,
    animation: false,
  };

  return (
    <SurveyStatisticDonutChartWrapperStyled>
      <div className="question-container">
        <p className="chart-title">{props.questionName}</p>
        <p className="edit-history" onClick={showModalHistory}>
          {TEXT_TRANSLATION.EDIT_HISTORY}
        </p>
      </div>

      {props.answers.length > 0 ? (
        <div className="chart-container">
          <Pie {...config} />

          <LegendStyled>
            {props.answers.map((item, index) => {
              const percent = item.percentAnswer.toFixed(
                NUMBER_OF_DECIMAL_PLACES
              );
              return (
                <LegendItemStyled
                  key={index}
                  color={colorForLegendConfigs[index]}
                >
                  <p className="legend-item-title">{item.answerName}</p>
                  <p>
                    <span className="legend-item-vale">
                      {item.quantityAnswer}
                    </span>
                    <span className="legend-item-percent">{percent}%</span>
                  </p>
                </LegendItemStyled>
              );
            })}
          </LegendStyled>
        </div>
      ) : (
        <div className="empty-chart-container">
          <Empty />
        </div>
      )}

      <Modal
        title={TEXT_TRANSLATION.EDIT_HISTORY}
        centered
        open={isModalHistoryOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        width="auto"
        style={{ maxWidth: "800px", minWidth: "520px" }}
      >
        <ModalContentStyled style={{ borderBottom: "0px" }}>
          <Table
            columns={columnsTableEditHistory}
            dataSource={historyData}
            pagination={{ defaultPageSize: 5 }}
            loading={loading}
            bordered
          />
        </ModalContentStyled>
      </Modal>
    </SurveyStatisticDonutChartWrapperStyled>
  );
}

const SurveyStatisticDonutChart = memo(SurveyStatisticDonutChartInternal);

export { SurveyStatisticDonutChart };
