import styled from "styled-components/macro";
import {
  PolygonWhiteSixEdgeUrl,
  ThumbNailUrl,
  pictureSvgs,
} from "constants/svgs";

export const ShumokuItemStyled = styled.div`
  position: relative;
  height: auto;

  padding: 24px 16px 16px;
  border: 1px solid #f0f0f0;
  background: #fafafa;

  width: 100%;

  .video-index {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);

    width: 50px;
    height: 50px;
    background: url(${PolygonWhiteSixEdgeUrl}) center no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;

    .order-number {
      position: absolute;
      font-weight: 500;
    }
  }

  .program-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
    line-height: 21px;
  }

  .program-body {
    display: flex;
    gap: 14rem;

    .video-item {
      flex: 0 0 107px;
      width: 107px;
      height: 96px;
      cursor: pointer;
      background: url(${ThumbNailUrl}) center no-repeat;
      background-size: cover;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: inline-block;

        background: url(${pictureSvgs.ImagePlaceholderUrl}) #eeeeee;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 64%;
      }

      .thumbnail-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .program-index {
      flex: 1;
      display: flex;
      gap: 8px;

      .shumoku-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 4;
      }

      .member-shumoku-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 6;
      }

      .shumoku-info-item {
        display: flex;
        align-items: center;

        .icon {
          width: 16px;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 100%;
            height: 100%;
            fill: #bfbfbf;
          }
        }

        .content {
          font-weight: 400;
          font-size: 12px;
          margin-left: 6rem;
          min-width: 40rem;
          max-width: 135rem;
        }

        .history {
          font-weight: bold;
          font-size: 12px;
          margin-left: 10rem;
          color: #0087ff;
          cursor: pointer;
        }
      }
    }
  }

  .btn-update-shumoku {
    width: 100%;
    margin-top: 12px;
  }

  // Media queries for responsiveness
  @media (max-width: 1400px) {
    .program-body {
      align-items: center;

      .program-index {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;

        .shumoku-info-item {
          .content {
            min-width: 55rem;
            max-width: 95rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .history {
            margin-left: 5rem;
          }
        }
      }

      .shumoku-info,
      .member-shumoku-info {
        flex: 1;
      }
    }
  }
  @media (max-width: 650px) {
    .program-body {
      align-items: center;

      .program-index {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;

        .shumoku-info-item {
          .content {
            min-width: 100rem;
            max-width: 165rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 0;
          }
          .history {
            margin-left: 5rem;
          }
        }
      }

      .shumoku-info,
      .member-shumoku-info {
        flex: 1;
      }
    }
  }
`;
