import { FormOutlined, RightOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import {
  CUSTOM_MODAL_CLASS_NAME,
  DEFAULT_SURVEY_PHASE_TEXT,
} from "constants/common";
import { TSurveyPhaseResponse } from "model/member";
import { Fragment, ReactElement, cloneElement, useState } from "react";
import { SurveyTypeWrapperStyled } from "./SelectSurveyNewbieModal.styled";
import { ESurveyPhase } from "model/survey";

type TSurveyListForModal = Omit<TSurveyPhaseResponse, "updatedAt"> & {
  phaseName?: string;
  updatedAt?: string;
};

type TSelectSurveyModalProps = {
  children: ReactElement;
  surveyList?: TSurveyListForModal[];
  onSelect: (phaseNumber: ESurveyPhase) => void;
  autoSelect?: boolean; // Allow auto select survey that is selected before
  modalTitle?: string;
};

function SelectSurveyNewbieModal(props: TSelectSurveyModalProps) {
  const {
    children,
    surveyList = [],
    onSelect,
    autoSelect,
    modalTitle = "アンケート結果を選択してください。",
  } = props;

  const [openModalSelect, setOpenModalSelect] = useState(false);

  const handleOpenModalSelection = () => {
    if (surveyList.length === 1) {
      onSelect(surveyList[0].phaseNumber);
      localStorage.setItem(
        DEFAULT_SURVEY_PHASE_TEXT,
        surveyList[0].phaseNumber.toString()
      );
      return;
    }

    if (autoSelect) {
      const previousSelectedSurvey = parseInt(
        localStorage.getItem(DEFAULT_SURVEY_PHASE_TEXT) || ""
      );
      const surveyIndex = surveyList.findIndex(
        (survey: TSurveyListForModal) =>
          survey.phaseNumber === previousSelectedSurvey
      );

      if (previousSelectedSurvey && surveyIndex !== -1) {
        onSelect(surveyList[surveyIndex].phaseNumber);
        return;
      }
    }

    setOpenModalSelect(true);
  };

  const handleSelectSurvey = (phaseNumber: number) => {
    onSelect(phaseNumber);
    setOpenModalSelect(false);
    localStorage.setItem(DEFAULT_SURVEY_PHASE_TEXT, phaseNumber.toString());
  };

  return (
    <Fragment>
      {cloneElement(children, {
        onClick: () => {
          // !To make sure children are clickable => children have to have a prop named "onClick"
          children.props.onClick?.();
          handleOpenModalSelection();
        },
      })}

      <Modal
        open={openModalSelect}
        onCancel={() => {
          setOpenModalSelect(false);
        }}
        centered
        width={572}
        maskClosable={false}
        title={modalTitle}
        className={CUSTOM_MODAL_CLASS_NAME}
        footer={null}
      >
        <Fragment>
          {surveyList.map((phase, index) => {
            return (
              <SurveyTypeWrapperStyled
                key={`${phase.phaseNumber}-${index}`}
                onClick={() => handleSelectSurvey(phase.phaseNumber)}
              >
                <div className="icon-survey-wrapper">
                  <FormOutlined />
                </div>
                <div className="survey-info">
                  <p className="survey-name">{phase.phaseName}</p>
                </div>
                <div className="icon-right-wrapper">
                  <RightOutlined />
                </div>
              </SurveyTypeWrapperStyled>
            );
          })}
        </Fragment>
      </Modal>
    </Fragment>
  );
}

export { SelectSurveyNewbieModal };
