import {
  DEFAULT_MODAL_PROPS,
  DEFAULT_SURVEY_PHASE_TEXT,
} from "constants/common";
import MESSAGES from "constants/messages";
import { GLOBAL_TRANSLATE } from "constants/translate";
import { useAppLoading, useAppLocation, useAppModal } from "contexts";
import surveyApi from "features/survey/surveyApi";
import { showErrorNotification } from "helpers/notification";
import {
  mappingSurveyForSubmit,
  splitQuestionsByCategory,
} from "helpers/survey";
import { SurveyLayout, TQuestionByCategory } from "layouts";
import { ESurveyPhase, TSurveyValues } from "model/survey";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  MemberDetailsRoute,
  TMemberAxtosParams,
  TMemberIdParams,
} from "routers/router";
import { SURVEY_PHASE_INTERMEDIATE_TRANSLATE } from "./SurveyPhaseIntermediatePage.translate";

const SurveyLayoutPhaseIntermediate = SurveyLayout<TSurveyValues>;

function SurveyPhaseIntermediatePage() {
  const [categoryList, setCategoryList] = useState<TQuestionByCategory[]>([]);
  const [surveyValues, setSurveyValues] = useState<TSurveyValues>();

  const { memberId = "" } = useParams<TMemberIdParams>();
  const { memberAxtosId = "" } = useParams<TMemberAxtosParams>();
  const { phaseNumber = 1 } = useParams();
  const { startLoading, stopLoading } = useAppLoading();
  const { backToPrevPage } = useAppLocation();
  const navigate = useNavigate();
  const { appModal } = useAppModal();

  const getPageTitle = (phaseNumber: string): string => {
    switch (phaseNumber) {
      case ESurveyPhase.GYM_PHASE_1.toString():
        return SURVEY_PHASE_INTERMEDIATE_TRANSLATE.SCREEN_TITLE_PHASE_1;
      case ESurveyPhase.GYM_PHASE_2.toString():
        return SURVEY_PHASE_INTERMEDIATE_TRANSLATE.SCREEN_TITLE_PHASE_2;
      case ESurveyPhase.JUNIOR_PHASE.toString():
        return SURVEY_PHASE_INTERMEDIATE_TRANSLATE.SCREEN_TITLE_JUNIOR;
      case ESurveyPhase.ADULT_PHASE.toString():
        return SURVEY_PHASE_INTERMEDIATE_TRANSLATE.SCREEN_TITLE_ADULT;
      default:
        return "";
    }
  };

  const pageTitle = getPageTitle(phaseNumber.toString());

  // Handlers
  const handleBackPage = () => {
    backToPrevPage(MemberDetailsRoute.genPath(memberId));
  };

  const handleSaveSurveyLocal = (values: TSurveyValues) => {
    setSurveyValues(values);
  };

  const saveSurvey = async (values: TSurveyValues) => {
    try {
      startLoading();
      const formattedSurvey = mappingSurveyForSubmit(values);

      let succeeded;

      if (phaseNumber === ESurveyPhase.GYM_PHASE_2.toString()) {
        ({ succeeded } = await surveyApi.submitSurveyIntermediate({
          memberId,
          questions: formattedSurvey,
        }));
      } else {
        ({ succeeded } = await surveyApi.submitSurveyAndCreateMember({
          memberAxtosId,
          questions: formattedSurvey,
        }));
      }

      if (succeeded) {
        navigate(MemberDetailsRoute.genPath(memberId), { replace: true });

        // Remove default survey phase
        localStorage.removeItem(DEFAULT_SURVEY_PHASE_TEXT);
      } else {
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      }
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  const handleFinishSurvey = (values: TSurveyValues) => {
    appModal.confirm({
      ...DEFAULT_MODAL_PROPS.CONFIRM,
      title: GLOBAL_TRANSLATE.CONFIRM_FINISH_SURVEY,
      onOk: () => saveSurvey(values),
    });
  };

  const getQuestionList = async () => {
    try {
      startLoading();
      const { data: questionList } = await surveyApi.getSurveyQuestions(
        +phaseNumber
      );
      const _categoryList = splitQuestionsByCategory({
        questionList,
        phaseNumber: +phaseNumber,
      });
      setCategoryList(_categoryList);
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  // Effects
  useEffect(() => {
    getQuestionList();
  }, []);

  return (
    <SurveyLayoutPhaseIntermediate
      categoryList={categoryList}
      onBackPage={handleBackPage}
      onSaveSurvey={handleSaveSurveyLocal}
      surveyValues={surveyValues}
      onFinishSurvey={handleFinishSurvey}
      pageTitle={pageTitle}
    />
  );
}

export { SurveyPhaseIntermediatePage };
