import { ProgramMainLayout } from "pages/TrainingProgramPage/layout/ProgramMainLayout/ProgramMainLayout";
import {
  ButtonWrapper,
  ProgramSurveyContainerStyled,
  ProgramSurveyStyled,
  SurveryItemTitleStyled,
  SurveyItemStyled,
  SurveyWrapperStyled,
} from "./ProgramSurvey.styled";
import { TEXT_TRANSLATE } from "pages/TrainingProgramPage/TrainingProgram.translate";
import { Button, Form, Radio } from "antd";
import { useEffect, useState } from "react";
import { useProgramContext } from "pages/TrainingProgramPage/ProgramContext/ProgramContext";
import { useAppDispatch, useAppSelector } from "hooks";
import { TProgramSurvey, programActions } from "features/program/programSlice";
import { PROGRAM_STEPS, SURVEYS } from "constants/program";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import { useAppLoading } from "contexts";

const TRANSLATION = {
  PLEASE_SELECT: "回答を選択してください。",
};

const PROGRAM_SURVEY_QUESTIONS = {
  PHASE_NUMBER: "1",
  FREQUENCY: "2",
  DURATION: "3",
};

const QUESTIONS: string[] = ["phaseNumber", "frequency", "duration"];

const navigateOptions: NavigateOptions = {
  replace: true,
};

const ProgramSurvey = () => {
  const { next } = useProgramContext();
  const { startLoading, stopLoading } = useAppLoading();

  const [form] = Form.useForm();
  const [_searchParams, setSearchParams] = useSearchParams({});

  const dispatch = useAppDispatch();
  const { programSurvey, loading } = useAppSelector((state) => state.program);

  const [selectedOptions, setSelectedOptions] = useState<any>({});
  const [validationErrors, setValidationErrors] = useState<any>({});

  const handleOptionChange = (groupId: string, option: number) => {
    setSelectedOptions((prevSelectedOptions: any) => ({
      ...prevSelectedOptions,
      [groupId]: option,
    }));

    setValidationErrors((prevValidationErrors: any) => ({
      ...prevValidationErrors,
      [groupId]: undefined, // Reset the error message when an option is selected
    }));
  };

  //1: phaseNumber
  //2: frequency
  //3: duration

  const getContainerLayout = (groupId: string) => {
    switch (groupId) {
      case "1":
        return "item--1";
      case "2":
        return "item--2";
      case "3":
        return "item--3";
      default:
        return "";
    }
  };

  const onFinish = () => {
    const newValidationErrors: any = {};

    SURVEYS.forEach((item) => {
      if (!selectedOptions[item.id]) {
        newValidationErrors[item.id] = {
          errors: [{ message: TRANSLATION.PLEASE_SELECT }],
        };
      }
    });

    setValidationErrors(newValidationErrors);

    if (Object.keys(newValidationErrors).length > 0) {
      return;
    }

    let data = {};
    Object.entries(selectedOptions).map(([_, value], index) => {
      data = { ...data, [QUESTIONS[index]]: value };
    });

    dispatch(programActions.setProgramSurvey({ data: data as TProgramSurvey }));
    next();
  };

  useEffect(() => {
    setSelectedOptions({
      [PROGRAM_SURVEY_QUESTIONS.PHASE_NUMBER]: programSurvey.phaseNumber,
      [PROGRAM_SURVEY_QUESTIONS.FREQUENCY]: programSurvey.frequency,
      [PROGRAM_SURVEY_QUESTIONS.DURATION]: programSurvey.duration,
    });
  }, [
    programSurvey.phaseNumber,
    programSurvey.frequency,
    programSurvey.duration,
  ]);

  useEffect(() => {
    let data = {};
    Object.entries(selectedOptions).map(([_, value], index) => {
      data = { ...data, [QUESTIONS[index]]: value };
    });

    dispatch(programActions.setProgramSurvey({ data: data as TProgramSurvey }));
  }, [selectedOptions]);

  useEffect(() => {
    setSearchParams({ step: PROGRAM_STEPS.STEP_TWO }, navigateOptions);
  }, []);

  useEffect(() => {
    // Check if the default option has changed
    if (
      (selectedOptions[PROGRAM_SURVEY_QUESTIONS.PHASE_NUMBER] !==
        programSurvey.phaseNumber ||
        selectedOptions[PROGRAM_SURVEY_QUESTIONS.FREQUENCY] !==
          programSurvey.frequency ||
        selectedOptions[PROGRAM_SURVEY_QUESTIONS.DURATION] !==
          programSurvey.duration) &&
      (selectedOptions[PROGRAM_SURVEY_QUESTIONS.PHASE_NUMBER] !== undefined ||
        selectedOptions[PROGRAM_SURVEY_QUESTIONS.FREQUENCY] !== undefined ||
        selectedOptions[PROGRAM_SURVEY_QUESTIONS.DURATION] !== undefined)
    ) {
      dispatch(programActions.clearAllExtensions());
      dispatch(programActions.clearMemberShumokus());
    }
  }, [selectedOptions]);

  useEffect(() => {
    loading ? startLoading() : stopLoading();
    return stopLoading;
  }, [loading]);

  return (
    <ProgramMainLayout title={TEXT_TRANSLATE.DIET_TITLE}>
      <Form
        onFinish={onFinish}
        form={form}
        validateMessages={{
          required: TRANSLATION.PLEASE_SELECT,
        }}
      >
        <ProgramSurveyContainerStyled>
          <ProgramSurveyStyled>
            {SURVEYS.map((item) => {
              const containerLayout = getContainerLayout(item.id);
              return (
                <SurveyItemStyled key={item.id}>
                  <SurveryItemTitleStyled className={`${containerLayout}`}>
                    <span
                      className={`shape ${
                        validationErrors[item.id] ? "hasError" : ""
                      }`}
                    >
                      <span
                        className={`number ${
                          validationErrors[item.id] ? "hasError" : ""
                        }`}
                      >
                        {item.id}
                      </span>
                    </span>
                    <span
                      className={`title ${
                        validationErrors[item.id] ? "hasError" : ""
                      }`}
                    >
                      {item.title}
                    </span>
                  </SurveryItemTitleStyled>
                  <SurveyWrapperStyled className={`${containerLayout} `}>
                    <Radio.Group
                      name={item.title}
                      className={`${containerLayout} radio-group`}
                      value={selectedOptions[item.id]}
                      onChange={(e) =>
                        handleOptionChange(item.id, e.target.value)
                      }
                    >
                      {item.options.map((option) => {
                        const isSelected =
                          selectedOptions[item.id] === option.value;
                        const radioClassName = isSelected
                          ? "radio-item selected"
                          : "radio-item";

                        return (
                          <div key={option.id} className="radio-container">
                            <Radio
                              value={option.value}
                              className={radioClassName}
                              disabled={
                                item.id ===
                                PROGRAM_SURVEY_QUESTIONS.PHASE_NUMBER
                              }
                              onClick={() =>
                                handleOptionChange(item.id, option.value)
                              }
                            >
                              {option.name}
                            </Radio>
                          </div>
                        );
                      })}
                    </Radio.Group>
                    {validationErrors[item.id] && (
                      <div className="error-message">
                        {validationErrors[item.id]?.errors[0].message}
                      </div>
                    )}
                  </SurveyWrapperStyled>
                </SurveyItemStyled>
              );
            })}
          </ProgramSurveyStyled>
          <ButtonWrapper>
            <Button type="primary" htmlType="submit" className="btn-next">
              次へ
            </Button>
          </ButtonWrapper>
        </ProgramSurveyContainerStyled>
      </Form>
    </ProgramMainLayout>
  );
};

export { ProgramSurvey };
