import { AutoComplete, Button, Form, Input, Modal, Select, Spin } from "antd";
import { AppInputNumber } from "components";
import {
  ARRAY_SELECT_OPTION,
  DEFAULT_DEBOUNCE_TIME,
  MAX_LENGTH_NOTE,
  MAX_LENGTH_REPS,
  MAX_LENGTH_SETS,
  MAX_WEIGHT_LEVEL,
  MIN_WEIGHT_LEVEL,
  MULTIPLE_WEIGHT_LEVEL,
  NOTE_FIELD,
  NUMBER_OF_DECIMAL_PLACES,
  PLACEHOLDER_INPUT_NOTE,
  PLACEHOLDER_INPUT_NUMBER,
  WEIGHT_FIELD,
  WEIGHT_FIELD_DISPLAY,
  getNumberInString,
  getTextOnly,
} from "constants/common";
import images from "constants/images";
import MESSAGES from "constants/messages";
import programApi from "features/program/programApi";
import { replaceErrorImage } from "helpers";
import { TShumoku, TSubmitMemberShumokuModalValue } from "model/program";
import {
  Fragment,
  ReactElement,
  cloneElement,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import {
  ModalContentStyled,
  OptionItemStyled,
  SelectShumokuModalStyled,
} from "./UpdateMemberShumokuModal.style";
import { useAppSelector } from "hooks";
import { showErrorNotification } from "helpers/notification";

type TSelectShumokuModalProps = {
  onSubmit: (props: TSubmitMemberShumokuModalValue) => void;
  initialShumoku?: TShumoku;
  isUpdate?: boolean;
  children: ReactElement;
};

type TFormValues = {
  option: string;
  rep: string | number;
  search: string;
  sets: string | number;
  weightLevel: string | number;
  note: string;
};

const FIELD_NAMES = {
  REP: "rep",
  SETS: "sets",
  SEARCH: "search",
  OPTION: "option",
  WEIGHT_LEVEL: "weightLevel",
  NOTE: "note",
};

const TRANSLATION = {
  NO_DATA: "を入力してくださ",
  NOT_PROPER: "オプションの結果を選択してください",
  MESSAGE_REQUIRE_SHUMOKU_NAME: MESSAGES["COM-MSG-001"]("種目"),
  MESSAGE_REQUIRE_SHUMOKU_REP: MESSAGES["COM-MSG-001"]("回数"),
  MESSAGE_REQUIRE_SHUMOKU_SETS: MESSAGES["COM-MSG-001"]("セット"),
  MESSAGE_REQUIRE_MEMBER_SHUMOKU_WEIGHT_LEVEL: MESSAGES["COM-MSG-001"](
    `${WEIGHT_FIELD}`
  ),
  MESSAGE_REQUIRE_MEMBER_SHUMOKU_NOTE: MESSAGES["COM-MSG-001"](`${NOTE_FIELD}`),
};

function UpdateMemberShumokuModal(props: TSelectShumokuModalProps) {
  const { onSubmit, initialShumoku, isUpdate, children } = props;

  // State
  const [searchText, setSearchText] = useState("");
  const [shumokuOptions, setShumokuOptions] = useState<TShumoku[]>([]);
  const [loadingShumokuOptions, setLoadingShumokuOptions] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const isSelectItem = useRef(false);

  // Form
  const [form] = Form.useForm();
  const formId = useId();

  // Redux
  const { storeId } = useAppSelector((state) => state.program);
  const { memberShumokus } = useAppSelector((state) => ({
    memberShumokus: state.program.memberShumokus,
  }));

  const matchingMemberShumoku = memberShumokus?.find(
    (memberShumoku) => memberShumoku.shumokuMasterId === initialShumoku?.id
  );

  // Variables
  const formName = `shumoku-form-${formId}`;

  // Handlers
  const transformShumokuList = (shumokus: TShumoku[][]) => {
    let shumokuList: TShumoku[] = [];

    shumokus.map((item) => {
      shumokuList = [...shumokuList, ...item];
    });

    return shumokuList;
  };

  const handleSearch = async (value: string) => {
    try {
      setLoadingShumokuOptions(true);

      const { data } = await programApi.getShumokus(value.trim(), storeId);
      const transformedShumokuList = transformShumokuList(
        data as unknown as TShumoku[][]
      );

      setShumokuOptions(transformedShumokuList);
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      setLoadingShumokuOptions(false);
    }
  };

  const handleModalCancel = () => {
    // Clear the search text and data source when the Modal is closed
    setSearchText("");
    setShumokuOptions([]);
    form.resetFields();

    setOpenModal(false);
  };
  const handleFocus = () => {
    setIsOpenDropdown(true);
  };

  const handleBlur = () => {
    setIsOpenDropdown(false);
  };

  const handleClearShumoku = () => {
    form.setFieldValue(FIELD_NAMES.SEARCH, "");
    setSearchText("");
    setIsOpenDropdown(true);
  };

  const handleChange = () => {
    if (isSelectItem.current) {
      return;
    }
    setIsOpenDropdown(true);
  };

  const handleSelect = (item: TShumoku) => {
    setSearchText(item.shumokuName);
    form.setFieldValue(FIELD_NAMES.SEARCH, item.shumokuName);
    setIsOpenDropdown(false);
    isSelectItem.current = true;
  };

  const handleFormSubmit = async (values: TFormValues) => {
    const weightLevel = parseFloat(values?.weightLevel as string);
    const note = values?.note?.trim();
    const shumokuMasterId = initialShumoku?.id;

    try {
      const data: TSubmitMemberShumokuModalValue = {
        shumokuMasterId,
        weightLevel,
        note,
      };

      onSubmit(data);
      setOpenModal(false);
    } catch (error: any) {
      // Handle form validation errors
      form.setFields([
        {
          name: FIELD_NAMES.SEARCH,
          errors: [error.message],
        },
      ]);
    }
  };

  // Effects
  useEffect(() => {
    if (openModal && initialShumoku) {
      form.setFieldsValue({
        [FIELD_NAMES.SEARCH]: initialShumoku.shumokuName,
        [FIELD_NAMES.SETS]: initialShumoku.shumokuSetNumber,
        [FIELD_NAMES.REP]: getNumberInString(
          initialShumoku.shumokuTimes as string
        ),
        [FIELD_NAMES.WEIGHT_LEVEL]: initialShumoku.memberShumoku
          ? initialShumoku.memberShumoku.weightLevel
            ? Number(initialShumoku.memberShumoku.weightLevel).toFixed(
                NUMBER_OF_DECIMAL_PLACES
              )
            : ""
          : matchingMemberShumoku?.weightLevel
          ? Number(matchingMemberShumoku.weightLevel).toFixed(
              NUMBER_OF_DECIMAL_PLACES
            )
          : "",
        [FIELD_NAMES.NOTE]: initialShumoku.memberShumoku
          ? initialShumoku.memberShumoku.note
          : matchingMemberShumoku?.note,
        [FIELD_NAMES.OPTION]: getTextOnly(
          initialShumoku?.shumokuTimes as string
        ),
      });
      setSearchText(initialShumoku.shumokuName || "");
    } else {
      const shumokuUnit = form.getFieldValue(FIELD_NAMES.OPTION);

      if (!shumokuUnit) {
        form.setFieldsValue({
          [FIELD_NAMES.OPTION]: ARRAY_SELECT_OPTION[0].value,
        });
      }
    }
  }, [initialShumoku, openModal]);

  // Effects
  useEffect(() => {
    if (!openModal) return;
    isSelectItem.current = false;

    const timerId = setTimeout(() => {
      handleSearch(searchText);
    }, DEFAULT_DEBOUNCE_TIME);

    return () => clearTimeout(timerId);
  }, [searchText, openModal]);

  // Partial render
  const renderOption = (item: TShumoku) => {
    // Highlight the matched text in the dropdown options
    const matchIndex = item.shumokuName
      .toLowerCase()
      .indexOf(searchText.toLowerCase());
    const title = (
      <span>
        {item.shumokuName.substr(0, matchIndex)}
        <span style={{ fontWeight: "bold", color: "var(--color-primary)" }}>
          {item.shumokuName.substr(matchIndex, searchText.length)}
        </span>
        {item.shumokuName.substr(matchIndex + searchText.length)}
      </span>
    );

    const majorItemName = (
      <span style={{ fontWeight: "bold" }}>({item.majorItemName})</span>
    );

    return (
      <AutoComplete.Option
        key={item.id}
        value={item.shumokuName}
        style={{ background: "var(--color-white)" }}
      >
        <OptionItemStyled onClick={() => handleSelect(item)}>
          <div className="option-item">
            <img
              src={item.thumbnail || images.ThumbnailImage}
              alt="Thumbnail"
              onError={replaceErrorImage}
            />
          </div>
          <span>
            {title} {majorItemName}
          </span>
        </OptionItemStyled>
      </AutoComplete.Option>
    );
  };

  const renderNoDataOption = (
    <AutoComplete.Option key="no-data" disabled>
      データなし
    </AutoComplete.Option>
  );

  const loadingOptionUI = (
    <AutoComplete.Option key="loading" disabled>
      <Spin
        style={{
          marginInline: "auto",
          display: "block",
        }}
      />
    </AutoComplete.Option>
  );

  const buttonSubmitUI = (
    <Button key="btn-submit" type="primary" htmlType="submit" form={formName}>
      確定
    </Button>
  );

  return (
    <Fragment>
      {cloneElement(children, {
        onClick: () => {
          children.props.onClick?.();
          setOpenModal(true);
        },
      })}

      <SelectShumokuModalStyled>
        <Modal
          open={openModal}
          centered
          title="種目選択"
          onCancel={handleModalCancel}
          maskClosable={false}
          footer={[buttonSubmitUI]}
          width={600}
        >
          <ModalContentStyled>
            <Form form={form} name={formName} onFinish={handleFormSubmit}>
              {/* Shumoku name */}
              <Form.Item
                name={FIELD_NAMES.SEARCH}
                rules={[
                  {
                    required: true,
                    message: TRANSLATION.MESSAGE_REQUIRE_SHUMOKU_NAME,
                  },
                ]}
              >
                <AutoComplete
                  allowClear
                  open={isOpenDropdown}
                  dropdownMatchSelectWidth={false}
                  onSearch={(value) => setSearchText(value.trim())}
                  placeholder="種目を検索してください"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className={
                    form.getFieldError(FIELD_NAMES.SEARCH)?.length
                      ? "error-field"
                      : ""
                  }
                  defaultActiveFirstOption={true}
                  onClear={handleClearShumoku}
                  disabled={isUpdate ? false : true}
                >
                  {loadingShumokuOptions
                    ? loadingOptionUI
                    : shumokuOptions.length > 0
                    ? shumokuOptions.map(renderOption)
                    : renderNoDataOption}
                </AutoComplete>
              </Form.Item>

              {/* Reps */}
              <div style={{ display: "flex" }}>
                <div style={{ flex: "1 1 30%" }}>
                  <Form.Item name={FIELD_NAMES.OPTION}>
                    <Select
                      options={ARRAY_SELECT_OPTION}
                      disabled={isUpdate ? false : true}
                    />
                  </Form.Item>
                </div>
                <div style={{ flex: "1 1 70%" }}>
                  <Form.Item
                    name={FIELD_NAMES.REP}
                    rules={[
                      {
                        required: true,
                        message: TRANSLATION.MESSAGE_REQUIRE_SHUMOKU_REP,
                      },
                    ]}
                  >
                    <AppInputNumber
                      placeholder={PLACEHOLDER_INPUT_NUMBER}
                      onlyNumber
                      maxLength={MAX_LENGTH_REPS}
                      disabled={isUpdate ? false : true}
                    />
                  </Form.Item>
                </div>
              </div>

              {/* Sets */}
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    flex: "1 1 30%",
                    border: "solid 1px #D9D9D9",
                    paddingLeft: "10px",
                    paddingTop: "3px",
                    background: "#f5f5f5",
                    color: "#d2d2d2",
                    height: "32px",
                  }}
                >
                  セット
                </div>
                <div style={{ flex: "1 1 70%" }}>
                  <Form.Item
                    name={FIELD_NAMES.SETS}
                    rules={[
                      {
                        required: true,
                        message: TRANSLATION.MESSAGE_REQUIRE_SHUMOKU_SETS,
                      },
                    ]}
                  >
                    <AppInputNumber
                      placeholder={PLACEHOLDER_INPUT_NUMBER}
                      onlyNumber
                      maxLength={MAX_LENGTH_SETS}
                      disabled={isUpdate ? false : true}
                    />
                  </Form.Item>
                </div>
              </div>

              {/* Weight level */}
              {initialShumoku?.isDisplayWeightLevel && (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: "1 1 30%",
                      border: "solid 1px #D9D9D9",
                      paddingLeft: "10px",
                      paddingTop: "3px",
                      background: "#FAFAFA",
                      height: "32px",
                    }}
                  >
                    {WEIGHT_FIELD_DISPLAY}
                  </div>
                  <div style={{ flex: "1 1 70%" }}>
                    <Form.Item
                      name={FIELD_NAMES.WEIGHT_LEVEL}
                      rules={[
                        {
                          validator(_, value) {
                            if (
                              value === null ||
                              value === undefined ||
                              value === ""
                            ) {
                              return Promise.resolve();
                            }

                            if (
                              value < MIN_WEIGHT_LEVEL ||
                              value > MAX_WEIGHT_LEVEL
                            ) {
                              return Promise.reject(
                                new Error(MESSAGES["MIN-MAX-WEIGHT-LEVEL"])
                              );
                            }
                            if (value % MULTIPLE_WEIGHT_LEVEL !== 0) {
                              return Promise.reject(
                                new Error(MESSAGES["MULTIPLE-WEIGHT-LEVEL"])
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <AppInputNumber
                        placeholder={PLACEHOLDER_INPUT_NUMBER}
                        isLimitDecimal
                      />
                    </Form.Item>
                  </div>
                </div>
              )}

              {/* Note */}
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    flex: "1 1 30%",
                    border: "solid 1px #D9D9D9",
                    paddingLeft: "10px",
                    paddingTop: "3px",
                    background: "#FAFAFA",
                    height: "32px",
                  }}
                >
                  {NOTE_FIELD}
                </div>
                <div style={{ flex: "1 1 70%" }}>
                  <Form.Item name={FIELD_NAMES.NOTE}>
                    <Input
                      placeholder={PLACEHOLDER_INPUT_NOTE}
                      maxLength={MAX_LENGTH_NOTE}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </ModalContentStyled>
        </Modal>
      </SelectShumokuModalStyled>
    </Fragment>
  );
}

export { UpdateMemberShumokuModal };
