import { FC, useEffect } from "react";
import {
  ProgramFreeSelectionItemStyled,
  PublicProgramHeaderStyled,
  PublicProgramMainStyled,
  PublicProgramPageStyled,
} from "./PublicProgramPage.styled";
import { useAppDispatch, useAppSelector } from "hooks";
import { useAppLoading } from "contexts";
import { programActions } from "features/program/programSlice";
import { useSearchParams } from "react-router-dom";
import { TStepResponse } from "model/program";
import { ProgramSectionItemRef } from "./components/ProgramSectionItem/ProgramSectionItem";

const QUERY_PARAMS = {
  USER_ID: "userId",
  MEMBER_PROGRAM_ID: "memberProgramId",
};

const TRANSLATION = {
  PAGE_TITLE: "トレーニングメニュー",
};

const PublicProgramPage: FC = () => {
  const programData = useAppSelector((state) => state.program.publicProgram);
  const loading = useAppSelector((state) => state.program.loading);
  const dispatch = useAppDispatch();

  const { startLoading, stopLoading } = useAppLoading();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get(QUERY_PARAMS.USER_ID) || "";
  const memberProgramId =
    searchParams.get(QUERY_PARAMS.MEMBER_PROGRAM_ID) || "";

  const shumokuExtensions = {
    stepNumber: 5,
    stepName: "フリー選択",
    shumokus: programData?.shumokuExtensions || [],
  };

  useEffect(() => {
    dispatch(
      programActions.getPublicProgram({
        data: {
          userId,
          memberProgramId,
        },
      })
    );
  }, [userId, memberProgramId]);

  useEffect(() => {
    loading ? startLoading() : stopLoading();
    return stopLoading;
  }, [loading]);

  return (
    <PublicProgramPageStyled>
      <PublicProgramHeaderStyled>
        {TRANSLATION.PAGE_TITLE}
      </PublicProgramHeaderStyled>
      <PublicProgramMainStyled>
        {programData?.stepsResponse?.map((section: TStepResponse, index) => {
          return <ProgramSectionItemRef section={section} key={index} />;
        })}
        {shumokuExtensions.shumokus.length > 0 && (
          <ProgramFreeSelectionItemStyled>
            <ProgramSectionItemRef
              section={shumokuExtensions}
              key={shumokuExtensions.stepNumber}
              isUpdate={true}
            />
          </ProgramFreeSelectionItemStyled>
        )}
      </PublicProgramMainStyled>
    </PublicProgramPageStyled>
  );
};

export { PublicProgramPage };
